import React, { useState } from 'react';
import { Button, Col, Row, ListGroup, ListGroupItem, ListGroupItemHeading, Badge } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function EmailViewer({ children }) {
	const navigate = useNavigate();
	const { id } = useParams();
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	const [selectedRecipient, setSelectedRecipient] = useState(null);
	const { data, isLoading, refetch } = useQuery(['emailViewer', id], async () => {
		const data = await graphql(
			{
				query: `
				query ($id: bigint!) {
					sesEmailByPk(id: $id) {
						id
						subject
						html
						created
						recipients {
							id
							email
							name
							sent:messageId
							events {
								id
								type
								data
								timestamp
							}
						}
						opened: countRecipientEvents(args: {search: "Open"})
						bounced: countRecipientEvents(args: {search: "Bounce"})
						clicked: countRecipientEvents(args: {search: "Click"})
						complaints: countRecipientEvents(args: {search: "Complaint"})
						recipientsAggregate {
							aggregate {
								count
							}
						}
					}
				}
			`,
				variables: {
					id
				}
			},
			true
		);
		return data;
	});

	if (!isLoading) console.log(data);

	if (isLoading) return <Spinner />;

	const noOfRecipients = data.sesEmailByPk.recipientsAggregate.aggregate.count;
	return (
		<>
			<Row>
				<Col md={12}>
					{/* back button */}
					<Button outline onClick={() => navigate('/admin/emails')} color="primary">
						<i className="fas fa-arrow-left" />
						&nbsp; Back
					</Button>
					<Button style={{ float: 'right' }} outline onClick={refetch} color="primary">
						<i className="fas fa-sync" />
						&nbsp; Refresh
					</Button>
				</Col>
				<Col md={6}>
					<Title>Recipients</Title>
					<div style={{ textAlign: 'center' }}>
						<Badge color="primary">Sent {noOfRecipients}</Badge>
						{data.sesEmailByPk.opened > 0 && (
							<>
								&nbsp;
								<Badge color="secondary">
									Opened {data.sesEmailByPk.opened} ({Math.round((data.sesEmailByPk.opened / noOfRecipients) * 100)}
									%)
								</Badge>
							</>
						)}
						{data.sesEmailByPk.clicked > 0 && (
							<>
								&nbsp;
								<Badge color="success">
									Clicked {data.sesEmailByPk.clicked} ({Math.round((data.sesEmailByPk.clicked / noOfRecipients) * 100)}
									%)
								</Badge>
							</>
						)}
						{data.sesEmailByPk.bounced > 0 && (
							<>
								&nbsp;
								<Badge color="warning">
									Bounced {data.sesEmailByPk.bounced} ({Math.round((data.sesEmailByPk.bounced / noOfRecipients) * 100)}
									%)
								</Badge>
							</>
						)}
						{data.sesEmailByPk.complaints > 0 && (
							<>
								&nbsp;
								<Badge color="danger">Complaints {data.sesEmailByPk.complaints}</Badge>
							</>
						)}
					</div>
					<ListGroup style={{ maxHeight: '500px', overflowY: 'auto' }}>
						{data.sesEmailByPk.recipients
							// Move complaint recipients to the top
							.sort((a, b) => {
								if (a.events.some((event) => event.type === 'Complaint')) return -1;
								return 1;
							})
							.map((recipient) => (
								<ListGroupItem
									key={recipient.id}
									style={{
										'&:hover': {
											backgroundColor: 'red'
										}
									}}
									onClick={() => setSelectedRecipient(recipient)}
								>
									<ListGroupItemHeading className="mb-1">
										{recipient.name} {recipient.events.length > 0 && <Badge pill>{recipient.events.length}</Badge>}
										{recipient.events.some((event) => event.type === 'Complaint') && (
											<Badge style={{ float: 'right' }} color="danger" pill>
												Complained
											</Badge>
										)}
									</ListGroupItemHeading>
									{recipient.emailAddress}
								</ListGroupItem>
							))}
					</ListGroup>
				</Col>
				<Col md={6}>
					<Title>Recipient Events</Title>
					<ListGroup style={{ maxHeight: '500px', overflowY: 'auto' }}>
						{selectedRecipient && selectedRecipient.events.length > 0 ? (
							selectedRecipient.events.map((event) => (
								<ListGroupItem key={event.id}>
									<ListGroupItemHeading className="mb-1">
										{event.type}
										<Badge style={{ float: 'right' }} pill>
											{moment(event.timestamp).format('DD/MM/YYYY HH:mm')} ({moment(event.timestamp).fromNow()})
										</Badge>
									</ListGroupItemHeading>
								</ListGroupItem>
							))
						) : (
							<>No Events</>
						)}
					</ListGroup>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col md={12}>
					<Title>Email Preview</Title>
					<div dangerouslySetInnerHTML={{ __html: data.sesEmailByPk.html }} />
				</Col>
			</Row>
		</>
	);
}

function Title({ children }) {
	return <h5 style={{ textAlign: 'center', borderBottom: '2px solid #ccc', paddingBottom: '4px' }}>{children}</h5>;
}
