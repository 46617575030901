import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import LoadingButton from 'src/components/LoadingButton';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';

export default function ActivityModal({ state, setState, children, ...args }) {
	const queryClient = useQueryClient();

	const [saving, setSaving] = useState(false);
	const [theerror, setError] = useState(null);

	const handleChange = (e) => {
		console.log({ [e.target.name]: e.target.value });
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleClose = async () => {
		setSaving(false);
		setState(null);
		setError(null);
	};

	// const { data, isLoading, error, refetch } = useQuery(['ActivityAvailabilityModal'], async () => {
	// 	try {
	// 		const { activity } = await graphql(
	// 			{
	// 				query: `#graphql
	// 				{
	// 					activity(where:{product:{visible:{_eq:true}}}) {
	// 						id
	// 						name
	// 					}
	// 				}`
	// 			},
	// 			true
	// 		);
	// 		return { activity };
	// 	} catch (error) {
	// 		setError(error);
	// 	}
	// });

	const save = async () => {
		try {
			setError();
			setSaving(true);
			await graphql(
				{
					query: `#graphql
					mutation ($input: WorkshopAvailabilityInsertInput!) {
						insertWorkshopAvailabilityOne(
							object: $input,
							onConflict: {
								constraint: idx_22978_primary,
								updateColumns: [availabilityDate,availableSpaces]
							}
						) {
							availabilityDate
						}
					}
					# mutation ($date: date!, $input: WorkshopAvailabilitySetInput) {
  					# 	updateWorkshopAvailabilityByPk(pkColumns: {availabilityDate: $date}, _set: $input){
    				# 		availabilityDate
 					# 	}
					# }
					`,
					variables: {
						input: {
							availabilityDate: state.date ? state.date : null,
							availableSpaces: state.slots ? Number(state.slots) : 0,
							availabilityEndDate: state.date ? state.date : null
						}
					}
				},
				true
			);

			await queryClient.invalidateQueries('calendar');
			handleClose();
		} catch (error) {
			setError(error);
		}
	};

	return (
		<div>
			<Modal isOpen={!!state} toggle={handleClose} {...args}>
				<ModalHeader toggle={handleClose}>Workshop Availability</ModalHeader>
				<ModalBody>
					{!state ? (
						<Spinner />
					) : (
						<>
							{theerror && <Alert color="danger">{theerror.message || 'Something went wrong'}</Alert>}

							<FormGroup floating>
								<Input
									name="slots"
									onChange={handleChange}
									value={state.slots}
									id="slots"
									placeholder="slots"
									type="number"
								/>
								<Label for="slots">Available Slots</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									name="date"
									onChange={handleChange}
									value={state.date}
									id="date"
									placeholder="date"
									type="date"
								/>
								<Label for="date">Date</Label>
							</FormGroup>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					{state && (
						<>
							<LoadingButton loading={saving} disabled={!state.date} color="primary" onClick={save}>
								Save
							</LoadingButton>
							<Button color="secondary" onClick={handleClose}>
								Cancel
							</Button>
						</>
					)}
				</ModalFooter>
			</Modal>
		</div>
	);
}
