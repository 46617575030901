import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Alert } from 'reactstrap';

export default function AskConfirm(props) {
	const {
		title = 'Are you sure?',
		description = '',
		submitLabel = 'Yes',
		onSubmit = () => {},
		submitColor = 'primary'
	} = props;
	const [open, setOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(false);

	const openDialog = () => {
		setError(false);
		setSaving(false);
		setOpen(true);
	};

	const closeDialog = () => {
		if (saving) return;
		setError(false);
		setSaving(false);
		setOpen(false);
	};

	const handleSubmit = async (response) => {
		if (saving) return;
		setSaving(true);
		setError(false);
		let res = null;
		try {
			res = await onSubmit(response);
			if (!res) return setOpen(false);
			setError(res);
		} catch (error) {
			setError(error.message);
		} finally {
			setSaving(false);
		}
	};

	const openButton = props.children ? (
		<span onClick={openDialog}>{props.children}</span>
	) : (
		<i id="askConfirmTooltip" onClick={openDialog} className="fa fa-pencil-alt" />
	);

	return (
		<>
			{openButton}
			{!props.children && (
				<>
					<UncontrolledTooltip placement="right" target="askConfirmTooltip" trigger="hover">
						Click to edit
					</UncontrolledTooltip>
				</>
			)}
			<Modal fullWidth isOpen={open} toggle={closeDialog} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					{description && description}
				</ModalBody>
				<ModalFooter>
					<Button disabled={saving} onClick={closeDialog} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color={submitColor}>
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
