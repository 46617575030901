import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import LoadingButton from 'src/components/LoadingButton';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';

export default function ClosureModal({ state, setState, children, ...args }) {
	const queryClient = useQueryClient();
	const [deleting, setDeleting] = useState(false);
	const [saving, setSaving] = useState(false);
	const [theerror, setError] = useState(null);

	const handleChange = (e) => {
		console.log({ [e.target.name]: e.target.value });
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleClose = async () => {
		setSaving(false);
		setDeleting(false);
		setState(null);
		setError(null);
	};

	const { data, isLoading, error, refetch } = useQuery(['closureModal'], async () => {
		try {
			const { closeReason } = await graphql(
				{
					query: `#graphql
				{
					closeReason {
						id
						name
					}
				}`
				},
				true
			);
			return { closeReason };
		} catch (error) {
			setError(error);
		}
	});

	const save = async () => {
		try {
			setError();
			setSaving(true);
			await graphql({
				query: `#graphql
					mutation($input: ClosureInput!) {
						saveClosure(input: $input)
					}
					`,
				variables: {
					input: {
						...state
					}
				}
			});

			await queryClient.invalidateQueries('calendar');
			handleClose();
		} catch (error) {
			setError(error);
		}
	};

	const deleteClosure = async () => {
		try {
			setError();
			setDeleting(true);
			await graphql(
				{
					query: `#graphql
				mutation ($id:bigint!){
					deleteCloseDateByPk(id:$id) {
						id
					}
				}`,
					variables: {
						id: state.id
					}
				},
				true
			);
			await queryClient.invalidateQueries('calendar');
			handleClose();
		} catch (error) {
			setError(error);
		} finally {
			setDeleting(false);
		}
	};

	return (
		<div>
			<Modal isOpen={!!state} toggle={handleClose} {...args}>
				<ModalHeader toggle={handleClose}>Closure</ModalHeader>
				<ModalBody>
					{isLoading || !state ? (
						<Spinner />
					) : (
						<>
							{theerror && <Alert color="danger">{theerror.message || 'Something went wrong'}</Alert>}
							<FormGroup floating>
								<Input
									name="reasonId"
									onChange={handleChange}
									value={state.reasonId}
									id="reason"
									placeholder="reason"
									type="select"
								>
									<option value="">Select Reason</option>
									{data.closeReason.map((e) => (
										<option key={e.id} value={e.id}>
											{e.name}
										</option>
									))}
								</Input>
								<Label for="reason">Close Reason</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									name="startDate"
									onChange={handleChange}
									value={state.startDate}
									id="startDate"
									placeholder="startDate"
									type="date"
								/>
								<Label for="startDate">Start Date</Label>
							</FormGroup>
							<FormGroup floating>
								<Input
									name="endDate"
									onChange={handleChange}
									value={state.endDate}
									id="endDate"
									placeholder="End Date"
									type="date"
								/>
								<Label for="startDate">End Date</Label>
							</FormGroup>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					{state && (
						<>
							{state.id && (
								<LoadingButton onClick={deleteClosure} loading={deleting} color="danger">
									Delete
								</LoadingButton>
							)}
							<LoadingButton
								loading={saving}
								disabled={!state.startDate || !state.endDate || !state.reasonId}
								color="primary"
								onClick={save}
							>
								{state.id ? 'Save' : 'Add'}
							</LoadingButton>
							<Button color="secondary" onClick={handleClose}>
								Cancel
							</Button>
						</>
					)}
				</ModalFooter>
			</Modal>
		</div>
	);
}
