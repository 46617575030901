import React, { useState, useEffect, useCallback } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Col,
	Input,
	Label,
	InputGroup,
	ListGroup,
	ListGroupItem,
	FormGroup
} from 'reactstrap';
import Spinner from 'src/components/Spinner';
import axios from 'src/utils/axios';
import graphql from 'src/utils/graphql';
export default function PickerModal(props) {
	const { listType, list, setList, roles } = props;
	const [show, setShow] = useState(false);
	const [search, setSearch] = useState('');
	const [searchList, setSearchList] = useState([]);
	const [filteredList, setFilteredList] = useState([]);
	const [type, setType] = useState('person');
	const [instructor, setInstructor] = useState(-1);
	const [role, setRole] = useState('%');
	const toggle = () => {
		setShow(!show);
	};

	useEffect(() => {
		// Set filtered list to results with already selected contacts removed.
		setFilteredList(searchList.filter((p) => (list.find((l) => l.email === p.email) ? false : true)));
	}, [searchList, list]);

	const toggleSelected = (object) => {
		var _list = [...list];
		var index = _list.indexOf(object);
		if (index === -1) _list.push(object);
		else _list.splice(index, 1);
		setList(_list);
	};

	const moveAll = () => setList([...list, ...filteredList.map((e) => e)]);

	const fetch = useCallback(async () => {
		const data = await graphql(
			{
				query: `
			query ($search: String!) {
				person(where: {personArchive: {_eq: false}, emailMarketing: {_eq: true}, name:{_ilike:$search}}) {
					name
					email
				}
			}
			`,
				variables: {
					search: `%${search}%`
				}
			},
			true
		);
		setSearchList(data.person);
	}, [search]);

	useEffect(() => {
		fetch();
	}, [search, type, instructor, role, fetch]);

	const inputTypeString = listType.charAt(0).toUpperCase() + listType.slice(1);

	const inputPreview = list.length > 0 ? `${list.length} Selected` : '';

	if (!roles) return <Spinner />;
	return (
		<div>
			<InputGroup>
				<FormGroup floating>
					<Input value={inputPreview} disabled />
					<Label>{inputTypeString}</Label>
				</FormGroup>
				<Button
					style={{
						height: '58px'
					}}
					onClick={() => setShow(true)}
					color="success"
				>
					<i className="fa fa-plus" />
				</Button>
			</InputGroup>
			<Modal style={{ maxWidth: '70vw' }} isOpen={show} toggle={toggle}>
				<ModalHeader toggle={toggle}>Recipient Picker</ModalHeader>
				<ModalBody>
					<Row>
						{/* <Col md="4">
							<Label>Parner/Person</Label>
							<Input
								className="mb-3"
								type="select"
								onChange={(e) => {
									setType(e.target);
								}}
							>
								<option value="person">Person</option>
								<option value="partner">Partner</option>
								<option value="person_partner">Person & Parters</option>
							</Input>
							<Label>Instructor</Label>
							<Input
								className="mb-3"
								type="select"
								onChange={(e) => {
									setInstructor(Number(e.target.value));
								}}
							>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
								<option value={-1}>Ignore</option>
							</Input>
							<Label>Roles</Label>
							<Input
								type="select"
								value={role}
								onChange={(e) => {
									setRole(e.target.value);
								}}
							>
								<option value="%">N/A</option>
								{roles.map((r) => {
									return (
										<option key={r.role_id} value={r.role_id}>
											{r.role_name}
										</option>
									);
								})}
							</Input>
						</Col> */}
						<Col md="6">
							<Input value={search} onChange={(e) => setSearch(e.target.value)} />
							<ListGroup className="mt-1">
								<ListGroupItem>
									Move all
									<Button onClick={moveAll} size="sm" color="success" className="float-end">
										<i className="fa fa-angle-double-right" />
									</Button>
								</ListGroupItem>
								{filteredList.map((p, index) => {
									if (!p) return false;
									return (
										<ListGroupItem key={index} style={{ fontSize: '0.8em' }}>
											{p.email && (
												<Button onClick={() => toggleSelected(p)} size="sm" className="float-end">
													<i className="fa fa-arrow-right" />
												</Button>
											)}
											{p.name} ({p.email || <span style={{ color: 'red' }}>NO EMAIL</span>})
										</ListGroupItem>
									);
								})}
							</ListGroup>
						</Col>
						<Col md="6">
							<ListGroup className="mt-1">
								<ListGroupItem>
									Clear List
									<Button onClick={() => setList([])} size="sm" color="danger" className="float-end">
										<i className="fa fa-times" />
									</Button>
								</ListGroupItem>
								{list.map((p, index) => {
									if (!p) return false;
									return (
										<ListGroupItem key={index} style={{ fontSize: '0.8em' }}>
											<Button color="danger" onClick={() => toggleSelected(p)} size="sm" className="float-end">
												<i className="fa fa-arrow-left" />
											</Button>
											{p.name} ({p.email || <span style={{ color: 'red' }}>NO EMAIL</span>})
										</ListGroupItem>
									);
								})}
							</ListGroup>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={toggle}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
