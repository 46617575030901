import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';
import LoadingButton from 'src/components/LoadingButton';

export default function MembershipModal({ product, price, typeId, children, ...args }) {
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const toggle = () => setModal(!modal);
	const { user } = useApp();

	const createMembershipOrder = async () => {
		try {
			const resolvedPath = window.websiteEmbed
				? window.location.origin + window.location.pathname + '#'
				: window.location.origin;

			setLoading(true);
			const { createMembershipOrder } = await graphql({
				query: `#graphql
						mutation($typeId: ID!, $successURL: String!, $cancelURL: String!) {
							createMembershipOrder(typeId: $typeId, successURL: $successURL, cancelURL: $cancelURL)
						}
					`,
				variables: {
					typeId,
					successURL: resolvedPath + '/cart/thankyou?email=' + encodeURIComponent(user?.email) + '&noempty=1',
					cancelURL: `${resolvedPath}/membership`
				}
			});
			window.location = createMembershipOrder;
		} catch (error) {
			console.log(error);
			alert("Sorry, we couldn't fetch the cart");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<span onClick={toggle}>{children || 'Open'}</span>
			<Modal isOpen={modal} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Purchase Membership</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-12 col-md-6">
							<img src={product?.imageObject?.url} alt={product.name} className="img-fluid" />
						</div>
						<div className="col-12 col-md-6">
							<p>{product.description}</p>
							<p style={{ fontSize: '1.2em' }}>
								Price: <b>£{price}</b>&nbsp;
								<small style={{ color: 'red' }}>
									{price !== product.price && (
										<div style={{ fontWeight: 'bold', textDecoration: 'line-through', display: 'inline' }}>
											£{product.price}
										</div>
									)}
								</small>
							</p>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<LoadingButton loading={loading} color="primary" onClick={createMembershipOrder}>
						Purchase
					</LoadingButton>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
