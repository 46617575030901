import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import axios from 'src/utils/axios';
import TemplateModal from './TemplateModal';
import swal from 'src/utils/swal';
import Spinner from 'src/components/Spinner';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import { useNavigate } from 'react-router-dom';
import { AskConfirm } from 'src/components/AskComponents';

export default function EmailTemplate() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { data, isLoading, error } = useQuery(['emailTemplates'], async () => {
		const data = await graphql(
			{
				query: `{
					emailTemplate {
						id
						name
						content
						subject
					} 
				}`
			},
			true
		);
		return data;
	});

	const addTemplate = () => {
		swal
			.fire({
				title: 'Create Template',
				text: 'Please type a name for your template',
				input: 'text',
				showCancelButton: true
			})
			.then(async (result) => {
				if (result.isConfirmed) {
					await graphql({
						query: `mutation($input: EmailTemplateInput!) {
							saveEmailTemplate(input: $input)
						}`,
						variables: {
							input: {
								name: result.value
							}
						}
					});

					await queryClient.invalidateQueries('emailTemplates');
				}
			});
	};

	const createEmailFromTemplate = async (templateId) => {
		const { createEmailFromTemplate } = await graphql({
			query: `mutation($templateId: ID!) {
				createEmailFromTemplate(id: $templateId)
			}`,
			variables: {
				templateId
			}
		});
		navigate(`/admin/unlayer/${createEmailFromTemplate}`);
	};

	if (isLoading) return <Spinner />;
	return (
		<div>
			<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>
							<i style={{ fontSize: '1.6em' }} className="ml-2 fa fa-plus-circle" onClick={addTemplate} />
						</th>
					</tr>
				</thead>
				<tbody>
					{data.emailTemplate.map((t) => {
						return (
							<tr key={t.id}>
								<td>{t.name}</td>
								<td>
									{/* <TemplateModal template={t} /> */}
									<AskConfirm
										onSubmit={() => createEmailFromTemplate(t.id)}
										title="Send Email"
										description="Are you sure you want to create a copy of this template?"
									>
										<Button color="primary">Send Email</Button>
									</AskConfirm>
									&nbsp;
									<Button outline color="primary" onClick={() => navigate(`/admin/unlayer/${t.id}`)}>
										Edit Template
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	);
}
