import { useState, useEffect, useCallback } from 'react';
import axios from '../../../utils/axios';
import { Input, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/Spinner';

import { useApp } from 'src/components/App/appContext';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import graphql from 'src/utils/graphql';
import styled from 'styled-components';

const StyledTableContainer = styled.div`
	overflow-x: auto;
	@media (min-width: 768px) {
		overflow-x: visible;
	}
`;

export default function Pilots() {
	const { get } = useApp();
	const [search, setSearch] = useState('');
	const { data, isLoading, error, refetch } = useQuery(['people'], async () => {
		const { person } = await graphql(
			{
				query: `query ($search: String){
					person(where:{name:{_ilike: $search}}) {
						id
						name
						email
						mobile
						dob
					}
				}`,
				variables: { search: `%${search}%` }
			},
			true
		);
		return { person };
	});

	useEffect(() => {
		refetch();
	}, [search]);

	const getCSV = useCallback(async () => {
		const { dumpCSV } = await graphql({
			query: `mutation {
				dumpCSV
			}`
		});
		const data = new Blob([dumpCSV], { type: 'text/csv' });
		const url = window.URL.createObjectURL(data);
		window.location = url;
	}, []);

	if (isLoading) return <Spinner />;
	return (
		<>
			<button onClick={getCSV}>Download CSV</button>
			{/* search box */}
			<Input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
			<StyledTableContainer>
				<Table>
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Email</th>
							<th>Mobile</th>
							<th>Age</th>
						</tr>
					</thead>
					<tbody>
						{data?.person.map((p) => {
							// var age = Math.floor(p.age / 365);
							let age = moment().diff(moment(p.dob, 'YYYY-MM-DD'), 'years');
							return (
								<tr>
									<th>{p.id}</th>
									<td>
										<Link to={`/admin/pilot/${p.id}`}>{p.name}</Link>
									</td>
									<td>{p.email}</td>
									<td>{p.mobile}</td>
									<td>{age ? age : 'N/A'}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</StyledTableContainer>
		</>
	);
}
