import { useQuery } from '@tanstack/react-query';
import React from 'react';
import graphql from 'src/utils/graphql';
import EditProduct from 'src/components/EditProduct';
import { Card, CardBody, CardText, CardTitle, Col, Row, Table, Button, UncontrolledCollapse, Badge } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function Memberships() {
	const [showActiveMemberships, setShowActiveMemberships] = React.useState(true);
	const navigate = useNavigate();
	const { data, isLoading, refetch } = useQuery(['ManageMemberships'], async () => {
		const data = await graphql(
			{
				query: `
				query ($currentDate: timestamptz!) {
					clubMembershipType {
						product {
							id
							name
							description
							visible
							price
						}
					}
					clubMembership(where: {endDate: {_gt: $currentDate}}, orderBy: {endDate:ASC}) {
						id
						person {
							id
							name
							bhpaNumber
							bhpaExpiry
						}
						startDate
						endDate
						type {
							product {
								name
							}
						}
					}
					expired:clubMembership(where: {endDate: {_lte: $currentDate}}, orderBy: {endDate: DESC}) {
						id
						person {
							id
							name
						}
						startDate
						endDate
						type {
							product {
								name
							}
						}
					}
				}
			`,
				variables: {
					currentDate: new Date().toISOString()
				}
			},
			true
		);
		return { ...data };
	});

	if (isLoading) return <Spinner />;
	return (
		<>
			<h3 className="">Memberships</h3>
			<Row className="gx-3">
				{data.clubMembershipType.map((s, i) => {
					if (!s.product) return null;
					if (!s.product.visible) return null;
					const img = s.product.imageObject;
					return (
						<Col key={s.product.id} md={3} lg={2}>
							<EditProduct id={s.product.id} onSubmit={refetch}>
								<Card style={{ cursor: 'pointer' }}>
									<img src={img ? img.url : '/no-image.png'} alt={s.product.name} />
									<CardBody>
										<CardTitle>
											{s.product.name}
											<br />
											<b>£{s.product.price}</b>
										</CardTitle>
										<CardText>{s.product.description}</CardText>
									</CardBody>
								</Card>
							</EditProduct>
						</Col>
					);
				})}
			</Row>
			{data.clubMembershipType.filter((s) => s.product).some(({ product: p }) => !p.visible) && (
				<>
					<Button outline className="mt-2" color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
						Toggle Hidden Memberships
					</Button>
					<UncontrolledCollapse toggler="#toggler">
						<Row className="gx-3">
							{data.clubMembershipType.map((s, i) => {
								if (!s.product) return null;
								if (s.product.visible) return null;
								const img = s.product.imageObject;
								return (
									<Col key={s.product.id} md={3} lg={2}>
										<EditProduct id={s.product.id} onSubmit={refetch}>
											<Card style={{ cursor: 'pointer' }}>
												<img src={img ? img.url : '/no-image.png'} alt={s.product.name} />
												<CardBody>
													<CardTitle>
														{s.product.name}
														{!s.product.visible && <span className="text-danger"> (Hidden)</span>}
													</CardTitle>
													<CardText>{s.product.description}</CardText>
												</CardBody>
											</Card>
										</EditProduct>
									</Col>
								);
							})}
						</Row>
					</UncontrolledCollapse>
				</>
			)}
			<h3 className="mt-5">{showActiveMemberships ? 'Active ' : 'Expired '} Memberships</h3>
			<Button outline className="mt-2" color="primary" onClick={() => setShowActiveMemberships(!showActiveMemberships)}>
				{showActiveMemberships ? 'Show Expired' : 'Show Active'}
			</Button>
			<Table hover className="mt-2">
				<thead>
					<tr>
						<th>Client Name</th>
						<th>Membership Type</th>
						<th>Expire Date</th>
						<th>BHPA</th>
					</tr>
				</thead>
				<tbody>
					{showActiveMemberships
						? data.clubMembership &&
						  data.clubMembership.map((m, i) => {
								return (
									<tr key={i}>
										<td>
											<span
												style={{ color: '#227221', textDecoration: 'underline', cursor: 'pointer' }}
												onClick={() => navigate(`/admin/pilot/${m.person.id}`)}
											>
												{m.person.name}
											</span>
										</td>
										<td>{m.type.product.name}</td>
										<td>
											{new Date(m.endDate).toLocaleDateString('en-GB')}&nbsp; ({moment(m.endDate).fromNow()})
										</td>
										<th>{BHPABadge(m.person.bhpaNumber, m.person.bhpaExpiry)}</th>
									</tr>
								);
						  })
						: data.expired &&
						  data.expired.map((m, i) => {
								return (
									<tr key={i}>
										<td>{m.person.name}</td>
										<td>{m.type.product.name}</td>
										<td>
											{new Date(m.endDate).toLocaleDateString('en-GB')}&nbsp; ({moment(m.endDate).fromNow()})
										</td>
									</tr>
								);
						  })}
				</tbody>
			</Table>
		</>
	);
}

function BHPABadge(number, expiry) {
	let color = 'success';
	let text = 'Valid BHPA';
	if (!number) {
		color = 'danger';
		text = 'No BHPA Number';
	} else if (!expiry) {
		color = 'warning';
		text = 'Missing Expiry Date';
	} else if (new Date(expiry) < new Date()) {
		color = 'warning';
		text = 'Expired BHPA';
	}

	return (
		<Badge color={color} pill>
			{text}
		</Badge>
	);
}
