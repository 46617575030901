import axios from './axios';

const graphql = async (query, hasura) => {
	let res;

	if (hasura) {
		res = await axios.post('https://hasura.greendragons.co.uk/v1/graphql', {
			...query
		});
	} else {
		res = await axios.post('/graphql', {
			...query
		});
	}

	if (res.data.errors) {
		throw new Error(res.data.errors[0].message);
	}

	return res.data.data;
};

export default graphql;
