import React, { useEffect, useState } from 'react';
import { Container, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './index.css';
import AddVoucherModal from './AddVoucherModal';
import { useApp } from 'src/components/App/appContext';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import styled from 'styled-components';

const StyledTableContainer = styled.div`
	overflow-x: auto;
	@media (min-width: 768px) {
		overflow-x: visible;
	}
`;

export default function Vouchers() {
	const { user } = useApp();
	const [vouchers, setVouchers] = useState(null);
	const [activities, setActivities] = useState(null);

	const { data, isLoading, error } = useQuery(['vouchers'], async () => {
		const data = await graphql(
			{
				query: `
				{
					voucherCode(orderBy:{creationTime:DESC}) {
						id
						note
						creationTime
						expireDate
						activity {
							name
						}
						createdPerson {
							name
						}
						product {
							id
							name
						}
					}
					activity(where:{product:{visible:{_eq:true}}}) {
						id
						name
					}
				}
			`
			},
			true
		);
		if (!activities) setActivities(data.activity);
		return data;
	});

	if (isLoading) return <Spinner />;
	return (
		<Container>
			<div>
				<p>
					Unsure how to use this tool? <Link to="">Click</Link> here to watch the tutorial.
				</p>
			</div>
			<StyledTableContainer>
				{user.grantedPermits.find((p) => p.permit.name === 'EDIT_VOUCHERS') && (
					<AddVoucherModal activities={activities}>
						<Button style={{ float: 'right' }} color="success">
							<i className="fa fa-plus"></i>&nbsp;Add
						</Button>
					</AddVoucherModal>
				)}

				<span>Active Vouchers</span>

				<Table striped>
					<thead>
						<tr>
							<th>Code</th>
							<th>Activity</th>
							<th>Created</th>
							<th>Created By</th>
							<th>Expire&nbsp;Date</th>
							<th>Note</th>
						</tr>
					</thead>
					<tbody>
						{data.voucherCode.length ? (
							data.voucherCode.map((v, index) => {
								return <VoucherTableEntry key={index} voucher={v} />;
							})
						) : (
							<tr>
								<td colSpan={4}>No results</td>
							</tr>
						)}
					</tbody>
				</Table>
			</StyledTableContainer>
		</Container>
	);
}

const VoucherTableEntry = ({ voucher: v }) => {
	const created = new Date(v.creationTime).toLocaleDateString('en-GB');
	return (
		<tr>
			<td>{v.id}</td>
			<td>{v.activity ? v.activity.name : v.product.name}</td>
			<td>{created}</td>
			<td>{v.createdPerson?.name || 'System'}</td>
			<td>{v.expireDate}</td>
			<td>{v.note}</td>
		</tr>
	);
};
