import React from 'react';
import { Spinner as Spin } from 'reactstrap';

export default function Spinner({ children, noTransform = false }) {
	const message = children || 'Loading...';
	return (
		<div
			style={{
				textAlign: 'center',
				margin: noTransform ? '0' : '25% 0%',
				padding: '10px'
			}}
		>
			<Spin color="dark" />
			<p>{message}</p>
		</div>
	);
}
