import React, { useRef } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormGroup,
	Input,
	Label,
	UncontrolledTooltip,
	FormFeedback
} from 'reactstrap';

// import EditIcon from "@mui/icons-material/Edit";

export default function AskSelect(props) {
	const {
		title = 'Edit',
		description = '',
		placeholder = '',
		label = '',
		type,
		submitLabel = 'Save',
		onSubmit = () => {},
		required,
		value = null,
		values = [{ value: 1, text: 'set your defaults 🙄' }],
		search = false
	} = props;
	const [open, setOpen] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [searchValue, setSearchValue] = React.useState('');
	const [saving, setSaving] = React.useState(false);
	const [error, setError] = React.useState(false);
	const inputRef = useRef();
	const openDialog = () => {
		setInputValue(value);
		setError(false);
		setSaving(false);
		setOpen(true);
	};

	const handleSubmit = async () => {
		let newValue = inputValue;
		if (!newValue && required) return setError('Select a answer');
		if (saving) return;
		if (newValue) newValue = newValue.trim();
		setSaving(true);
		setError(false);
		let res = null;
		try {
			if (newValue !== value) res = await onSubmit(newValue);
			if (!res) return setOpen(false);
			else setError(res);
		} catch (error) {
			setSaving(false);
			setError(error.message);
		} finally {
			setSaving(false);
			inputRef.current?.focus();
		}
	};

	const openButton = props.children ? (
		<span onClick={openDialog} style={{ cursor: 'pointer' }}>
			{props.children}
		</span>
	) : (
		<i id="askSelectTooltip" onClick={openDialog} className="fa fa-pencil-alt ml-2" />
	);

	return (
		<>
			{openButton}
			{!props.children && (
				<>
					<UncontrolledTooltip placement="right" target="askSelectTooltip" trigger="hover">
						Click to edit
					</UncontrolledTooltip>
				</>
			)}

			<Modal isOpen={open} toggle={() => setOpen(!open)} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{description && <small>{description}</small>}
					<FormGroup>
						{label && <Label for="exampleEmail">{label}</Label>}
						{search && (
							<Input
								type="text"
								value={searchValue}
								name="text"
								onChange={(e) => {
									setSearchValue(e.target.value);
									setError(false);
								}}
								placeholder="Search"
								className="mb-2 mt-1"
							/>
						)}
						<Input
							innerRef={inputRef}
							type={type || 'select'}
							value={inputValue}
							name="text"
							onChange={(e) => {
								setInputValue(e.target.value.length > 0 ? e.target.value : null);
								setError(false);
							}}
							invalid={!!error}
							placeholder={placeholder}
							onKeyDown={(e) => {
								if (e.ctrlKey && e.key === 'Enter') handleSubmit();
							}}
						>
							{required ? <option value={''}>Please Select</option> : <option value={''}>-- None --</option>}
							{values
								.filter((val) => val.text.toLowerCase().includes(searchValue.toLowerCase()))
								.map((val, index) => {
									return (
										<option key={index} value={val.value}>
											{val.text}
										</option>
									);
								})}
						</Input>
						{error && <FormFeedback>{error}</FormFeedback>}
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary" disabled={required && inputValue === null}>
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
