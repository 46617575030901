import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default MySwal;
// MySwal.fire({
//   title: <p>Hello World</p>,
//   footer: "Copyright 2018",
// }).then(() => {
//   return MySwal.fire(<p>Shorthand works too</p>);
// });
