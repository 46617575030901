import React from 'react';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

export default function Payments() {
	const navigate = useNavigate();
	const { data, isLoading } = useQuery(['payments'], async () => {
		const data = await graphql(
			{
				query: `
				{
					payment(orderBy: {created: DESC}, where: {test: {_eq: false}}) {
						id
						amountTotal
						created
						paymentPerson {
							id
							name
							email
						}
					}
					paymentAggregate(where: {test: {_eq: false}}) {
						aggregate {
							sum {
								amountTotal
							}
						}
					}
				}
`
			},
			true
		);
		return data;
	});

	if (isLoading) return <Spinner />;
	return (
		<div>
			<h1>Payments</h1>
			<Card className="mb-2">
				<CardBody>
					Into GD Bank: <b>£{(data.paymentAggregate.aggregate.sum.amountTotal / 100).toFixed(2)}</b>
				</CardBody>
			</Card>
			<ListGroup>
				{data.payment.map((payment) => (
					<ListGroupItem key={payment.id}>
						{payment.paymentPerson ? (
							<a href="#" onClick={() => navigate(`/admin/pilot/${payment.paymentPerson.id}`)}>
								{payment.paymentPerson.name} <small>({payment.paymentPerson.email})</small>
							</a>
						) : (
							'Unknown'
						)}{' '}
						- <b>£{(payment.amountTotal / 100).toFixed(2)}</b>
						<span style={{ float: 'right' }}>{new Date(payment.created).toLocaleString()}</span>
					</ListGroupItem>
				))}
			</ListGroup>
		</div>
	);
}
