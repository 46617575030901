import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import styles from 'src/components/ClientLink/homepage.module.css';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import MembershipModal from './MembershipModal';
import './styles.css';
import { AskLogin } from 'src/components/AskComponents';
import BhpaDetailModal from './BhpaDetailModal';

const MembershipFragment = `
    offerPrice
    type {
      id
      product {
        id
        name
        description
        price
        giftable
      }
    }
`;

export default function Membership() {
	const { user } = useApp();
	const [memberships, setMemberships] = useState(null);
	const [currentMembership, setCurrentMembership] = useState(null);

	const fetchMemberships = useCallback(async () => {
		let meQuery = null;
		console.log('the user', user);
		if (user)
			meQuery = `
				me {
					id
					currentMembership {
						type {
							id
							name
						}
						membershipBalance
						startDate
						endDate
					}
					membershipOffers {
						${MembershipFragment}
					}
				}
		`;
		const data = await graphql(
			{
				query: `
			{
				${meQuery ? meQuery : ''}
				getMembershipOffers(args: {person_row: null}) {
					${MembershipFragment}
				}
			}
			`
			},
			true
		);
		const { getMembershipOffers } = data;
		const [me] = data.me?.length ? data.me : [];
		setMemberships(me ? me.membershipOffers : getMembershipOffers);
		setCurrentMembership(me ? me.currentMembership[0] : null);
	}, [user]);

	useEffect(() => {
		fetchMemberships();
	}, [fetchMemberships, user]);

	return (
		<div className="row">
			<div className="col-12 col-md-6">
				{currentMembership && user && (
					<div className={styles.membershipCard + ' mb-2'}>
						<Row className="text-xs-center">
							<Col sm={6} className="d-flex align-items-center justify-content-center">
								<img src="/gd-logo.png" alt="Logo" width="200px" />
							</Col>
							<Col sm={6}>
								<h2 style={{ fontWeight: '400' }}>
									<i className={'fa fa-user'} style={{ color: 'hsl(134, 20%, 70%)' }} />
									&nbsp; {user.name}
								</h2>
								Type: {currentMembership.type.name}
								<div className="mb-1" />
								{currentMembership.startDate && (
									<div>
										Started: {new Date(currentMembership.startDate).toLocaleDateString('en-GB')}
										<br />
										Expires: {new Date(currentMembership.endDate).toLocaleDateString('en-GB')}
									</div>
								)}
								<div className="mb-2" />
								<div className="text-xs-center">
									<img src={`https://api.qrserver.com/v1/create-qr-code/?size=70x70&color=118443&data=${user.id}`} />
								</div>
							</Col>
						</Row>
					</div>
				)}

				{memberships ? (
					memberships.map((o) => {
						const m = o.type;
						if (!m.product) return null;
						let Wrap = user ? MembershipModal : AskLogin;
						if (user && !user.bhpaNumber) {
							Wrap = BhpaDetailModal;
						}
						return (
							<Wrap
								key={m.product.id}
								product={m.product}
								typeId={o.type.id}
								price={o.offerPrice}
								message="Please login or register to purchase a membership"
								children={
									<MembershipCard
										title={m.product.name}
										price={m.product.price}
										offerPrice={o.offerPrice}
										description={m.product.description}
									/>
								}
							/>
						);
					})
				) : (
					<Spinner />
				)}
			</div>
			<div className="col-12 col-md-6">
				<h4>Membership Benefits</h4>

				<ul>
					<li className="mb-2">
						We offer coaching and theory classes in cross country flying, spot landing, active piloting, thermalling and
						more.
					</li>
					<li className="mb-2">
						Club members are welcome to come flying with us and have priority when we book UK trips, Thermal Chaser
						holidays and events organised by Green Dragons.
					</li>
					<li className="mb-2">
						You have access to all our flying sites including the North Downs, and use our winch and ground training
						fields.
					</li>
					<li className="mb-2">
						We run training sessions in ground handling and on site flight planning so that you can maximise your flying
						time and develop your skills.
					</li>
					<li className="mb-2">
						We will give advice on weather conditions and likely flying conditions. This will help you fly more and stay
						safe whilst you are at our flying sites.
					</li>
					<li className="mb-2">Special equipment and course offers are periodically available to members.</li>
					<li className="mb-2">
						Members have access to a daily morning flying weather report, given by an experienced instructor or coach,
						to help you get the best out of your flying day.
					</li>
					<li className="mb-2">
						Club members and their families are invited to events throughout the year including camping weekends, BBQs,
						Christmas parties and much more.
					</li>
				</ul>
			</div>
			<div className="col-12">
				<h4 className="mb-3 mt-4">The Green Dragons Flying Club</h4>
				<p>
					Joining the Green Dragons Club means you will fly more because you have a group of instructors, coaches and
					fellow pilots all working together. Being a member of the Green Dragons Club ensures that you are familiar
					with all of our pilots and flying sites. Working with fellow pilots to plan sessions and learning new
					techniques from our instructors will build your confidence.
				</p>
				<p>
					Our network of instructors and coaches have a wealth of knowledge about our private sites and the popular UK
					flying sites. They are always there to help you make the right decisions about when and where to fly according
					to your skillset. Being part of the club means that you will receive more coaching and training sessions
					whilst you are flying our private sites. It also means that you have a friendly face on the busy public sites
					and reassurance that someone is keeping an eye on you as you develop as a pilot.
				</p>
				<p>
					Simply put the Green Dragons Club is a perfect way to stay safe whilst you develop into a truly independent
					pilot. Our sole aim is to get you more airtime and give you the information and skills to progress your flying
					career.
				</p>
				<p>
					Please read our FAQs and T&Cs for more information and contact us if you have any questions. Have a look at
					our Flying Club Gallery or see our Facebook page for more inspiration.
				</p>
			</div>
		</div>
	);
}

const MembershipCard = ({ title, price, description, offerPrice }) => {
	return (
		<div className={styles.homepageLink + ' mb-2'}>
			<h2 style={{ fontWeight: '400' }}>
				<i className={'fa fa-star'} style={{ color: 'hsl(134, 20%, 70%)' }} />
				&nbsp;Purchase {title}
			</h2>
			{/* display price */}
			{offerPrice !== price && (
				<small style={{ fontWeight: 'bold', textDecoration: 'line-through', color: 'red' }}>£{price}</small>
			)}
			<div style={{ fontWeight: 'bold' }}>£{offerPrice}</div>
			<span>{description || 'Click to purchase this membership'}</span>
		</div>
	);
};
