import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import Spinner from 'src/components/Spinner';
import { Row, Button, Col } from 'reactstrap';

import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';

export default function Dashboard() {
	const { user } = useApp();
	const [links, setLinks] = useState(null);

	if (!user) return <Spinner />;
	return (
		<div className="mt-3">
			<Link to="/homepage">
				<Button color="danger" outline>
					<i className="fa fa-exclamation-triangle" /> You are staff, click here to view as a student
				</Button>
			</Link>
			<br />

			{user.dashboard.map((g, index) => {
				if (!user.admin && g.name === 'Administration') return null;
				return (
					<div className="mb-4" key={index}>
						<h2>{g.name}</h2>
						<hr />
						<Row>
							{g.links.map(({ title, icon, href, ...props }, index) => {
								return <DashIcon key={index} title={title} icon={icon} href={href} isNew={props.new} />;
							})}
						</Row>
					</div>
				);
			})}
		</div>
	);
}

function DashIcon(props) {
	const { title, icon = 'fa-question', href, isNew } = props;
	const disabled = href;
	return (
		<Col md={4} lg={2} sm={4} xs={12} className="adminIcon">
			<Link style={{ color: disabled ? 'green' : 'grey' }} to={href}>
				<i style={{ fontSize: '4em', padding: '15px' }} className={'fa ' + icon} />
				<br />
				{title}
			</Link>
		</Col>
	);
}
