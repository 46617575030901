import React, { useState, useEffect, useCallback } from 'react';
import { Table, Tooltip, Button, Row, Col } from 'reactstrap';

import { useApp } from 'src/components/App/appContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import { AskConfirm } from 'src/components/AskComponents';

export default function ThermalChasers() {
	const [trips, setTrips] = useState(null);
	const queryClient = useQueryClient();
	const { data, isLoading, error } = useQuery(['upcomingThermalChasers'], () => {
		const data = graphql({
			query: `
			{
				upcomingThermalChasers {
					id
					startDate
					endDate
					title
					ukOnly
					fullyBooked
					meRegistered
				}
			}`
		});
		return data;
	});

	const registerThermalChaser = async (id) => {
		await graphql({
			query: `
			mutation($id: ID!) {
				registerThermalChaser(id: $id)
			}`,
			variables: {
				id
			}
		});
		await queryClient.invalidateQueries(['upcomingThermalChasers']);
	};

	const unregisterThermalChaser = async (id) => {
		await graphql({
			query: `
			mutation($id: ID!) {
				unregisterThermalChaser(id: $id)
			}`,
			variables: {
				id
			}
		});
		await queryClient.invalidateQueries(['upcomingThermalChasers']);
	};

	if (isLoading) return <Spinner />;
	return (
		<div
			style={{
				backgroundImage: 'url(https://greendragonsairsports.co.uk/wp-content/uploads/2020/02/T3-1-scaled.jpg)',
				backgroundSize: 'cover',
				backgroundPosition: 'bottom',
				backgroundRepeat: 'no-repeat',
				color: 'white',
				borderRadius: '25px',
				padding: '20px'
			}}
		>
			<Row>
				<Col md={12} lg={6}>
					<h2 className="my-3">Thermal Chasers Flying Holidays</h2>
					<div
						style={{
							background: 'rgba(71, 116, 247, 0.6)',
							borderRadius: 18,
							padding: '12px 12px'
						}}
					>
						<div>International Thermal Chasers are currently on hold until further notice.</div>
						<br />
						<div>
							We are still planning on running UK Thermal Chaser trips, where we try and include as much as possible in
							the price so that you can simply focus on your flying and have fun!
						</div>
						<br />
						<div>
							Please register your interest for upcoming trips and we will contact you nearer the time to confirm plans
							and prices.
						</div>
						<br />
						<div>We include the below to ensure you have a worry free trip:</div>
						<br />
						<div>• Flights from the UK (International only)</div>
						<div>• Airport transport (International only)</div>
						<div>• Travel to and from the flying sites</div>
						<div>• Accommodation</div>
						<div>• Coaching from experienced BHPA instructors</div>
						<div>• Guiding from a local guide</div>
						<br />
						<div>We do not include:</div>
						<br />
						<div>• Flying equipment unless previously agreed</div>
						<div>• Travel and flying insurance</div>
						<div>• Radios, GPS and flight instruments</div>
						<div>• Meals and drinks</div>
						<br />
						<div>
							Please contact us if you have any questions or additional requirements, and see our FAQs and T&amp;Cs for
							more information.
						</div>
					</div>
				</Col>
				<Col md={12} lg={6}>
					<h3>Upcoming Thermal Chaser Trips</h3>
					<Table
						size="md"
						style={{
							color: 'white',
							background: 'rgba(71, 116, 247, 0.6)',
							borderRadius: '18px',
							padding: '12px 12px'
						}}
					>
						<thead>
							<tr>
								<th>Fly Location </th>
								<th> Fly Date </th>
								<th> Return&nbsp;Date </th>
								<th>Register Interest</th>
							</tr>
						</thead>
						<tbody>
							{data.upcomingThermalChasers.map((trip, index) => {
								return (
									<tr>
										<td>{trip.title}</td>
										<td>{trip.startDate}</td>
										<td>{trip.endDate}</td>
										{trip.meRegistered ? (
											<td>
												<AskConfirm
													title="Cancel Registration"
													description="Are you sure you want to cancel your registration?"
													onSubmit={() => unregisterThermalChaser(trip.id)}
												>
													<Button color="danger">Cancel Registration</Button>
												</AskConfirm>
											</td>
										) : trip.fully_booked ? (
											<td>
												<Button color="info">Fully booked</Button>
											</td>
										) : (
											<td>
												<AskConfirm
													title="Register"
													description="Are you sure you want to register for this trip?"
													onSubmit={() => registerThermalChaser(trip.id)}
												>
													<Button color="primary">Register</Button>
												</AskConfirm>
											</td>
										)}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
}
