import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	Badge,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	Navbar,
	NavbarBrand,
	NavbarText,
	UncontrolledDropdown
} from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import { CartContext } from 'src/contexts/CartContext';
import { AskLogin } from '../AskComponents';
import useMediaQuery from 'src/utils/useMediaQuery';

function TopBar(args) {
	const isXs = useMediaQuery('(max-width: 599px)');
	const navigator = useNavigate();
	const { user, logout, isLoaded } = useApp();
	const cart = useContext(CartContext);

	const cartItemCount = cart.items.reduce((acc, item) => acc + item.quantity, 0);

	const headless = window?.websiteEmbed;
	console.log('headless', headless);
	return (
		<>
			<div className="mb-4">
				<Navbar container color="light" light expand="md" {...args} style={headless && !isXs ? { height: '50px' } : {}}>
					{!headless && !isXs && (
						<NavbarBrand href="/">
							<img alt="Logo" style={{ maxHeight: '100px' }} src="/gd-logo.png" />
						</NavbarBrand>
					)}
					<Nav className="me-auto" navbar>
						<NavItem>
							<NavLink>
								<Link
									style={{
										textDecoration: 'none',
										color: '#0000008c'
									}}
									to="/"
								>
									{(headless || isXs) && (
										<>
											<i className="fa-solid fa-house" />
											&nbsp;
										</>
									)}
									Home
								</Link>
							</NavLink>
						</NavItem>
						{!isXs && (
							<NavItem>
								<NavLink>
									<Link
										style={{
											textDecoration: 'none',
											color: '#0000008c'
										}}
										to="/choose-sport"
									>
										{headless ? 'Choose Activity' : 'Products'}
									</Link>
								</NavLink>
							</NavItem>
						)}
					</Nav>
					<NavbarText>
						<Link to="/cart" style={{ marginRight: '10px' }}>
							<i className="fa-solid fa-cart-shopping fa-lg"></i>
							{!!cartItemCount && (
								<Badge pill color="success">
									{cartItemCount}
								</Badge>
							)}
						</Link>
						{user && (
							<UncontrolledDropdown style={{ display: 'inline-block' }} inNavbar>
								<DropdownToggle nav caret>
									<i className="fa fa-user" /> {user.name}
								</DropdownToggle>
								<DropdownMenu>
									<Link to="/profile" style={{ textDecoration: 'none' }}>
										<DropdownItem>
											<i className="fa-solid fa-id-card" />
											&nbsp;My Profile
										</DropdownItem>
									</Link>

									<DropdownItem divider />
									<DropdownItem onClick={logout}>
										<i className="fa fa-sign-out-alt" />
										&nbsp;Logout
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						)}
						{!user && isLoaded && <AskLogin message={'Please login or register to continue'} />}
					</NavbarText>
				</Navbar>
			</div>
		</>
	);
}

export default TopBar;
