import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useState } from 'react';
import { Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from 'src/components/LoadingButton';
import graphql from 'src/utils/graphql';

export default function PlanModal({ children, participation, participations, onSubmit }) {
	const queryClient = useQueryClient();
	const [isOpen, setIsOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [value, setValue] = useState(null);
	const [customMessage, setCustomMessage] = useState();

	const toggle = () => setIsOpen(!isOpen);

	const updateParticipations = async (IDs) => {
		let startTime = 0;
		let statusId = 1;
		let startCheckAm = false;
		let customMsg = null;

		if (value === 'cancelledBySchool') statusId = 2;
		else if (value === 'cancelledByPilot') statusId = 3;
		else if (value === 'checkAM') startCheckAm = true;
		else if (value === 'custom') customMsg = customMessage;
		else if (value !== 'none' && value !== null) startTime = value; // Default use time

		await graphql(
			{
				query: `
					mutation($startTime: Int!, $statusId: bigint!, $startCheckAm: Boolean!, $IDs: [bigint!]!, $customMessage: String!) {
						updateParticipationMany(updates: {_set: {startTime:$startTime, statusId: $statusId, startCheckAm: $startCheckAm,customMessage: $customMessage}, where:{id:{_in:$IDs}}}){
							affectedRows
						}
					}`,
				variables: {
					IDs,
					startTime,
					statusId,
					startCheckAm,
					customMessage: customMsg
				}
			},
			true
		);
	};

	const handleSave = async () => {
		setError(null);
		setSaving(true);
		try {
			// Single
			if (participation) await updateParticipations([participation.id]);
			// Multiple, filter out cancelled pilots
			else await updateParticipations(participations.filter((p) => p.status.id === 1).map((p) => p.id));
			await onSubmit();
			await queryClient.invalidateQueries('schedule');
			setIsOpen(false);
		} catch (error) {
			setError(error.message);
			console.error(error);
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			<span onClick={toggle}>{children}</span>
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader>
					<i className="fas fa-dove fa-fw" />
					Plan
				</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					{participation ? (
						<>
							<div className="mb-3 text-center">
								<h5>{participation.person.name}</h5>
								<h6>
									{participation.activity.name} (day {participation.activityIndex})<br />
									<small>{participation.pendingDays} day(s) pending</small>
								</h6>

								<h6>{participation.person.weight} KG</h6>
								<h6>{moment().diff(moment(participation.person.dob), 'years')} years old</h6>
								<a href={`tel:${participation.person.mobile}`}>
									<i className="fa fa-phone-alt"></i> {participation.person.mobile}
								</a>
							</div>
							<hr />
							<div className="text">
								<i className="fas fa-user fa-fw" /> Set {participation.person.name.trim()}'s booking to:
							</div>
						</>
					) : (
						<div className="text">
							<i className="fas fa-users fa-fw" /> Set everyone to:
						</div>
					)}
					<div className="mb-3" />
					<Input type="select" value={value} onChange={(e) => setValue(e.target.value)}>
						<option value="none">- None -</option>
						<option disabled>──────────</option>
						<option value="custom">Custom Message</option>
						<option disabled>──────────</option>
						<option value="cancelledBySchool">Cancelled by School</option>
						<option value="cancelledByPilot">Cancelled by Pilot</option>
						{/* divider */}
						<option disabled>──────────</option>
						<option value="checkAM">Check at 07:00 AM</option>
						<option disabled>──────────</option>
						{/* loop through times every 30min start at 5am to 20:00 */}
						{[...Array(40)].map((_, i) => {
							const time = moment(0, 'X')
								.utc()
								.startOf('day')
								.add(i * 30, 'minutes');

							if (time.unix() < 18000) return null; // cut off before 5
							if (time.unix() > 70000) return null; // cut off after 19

							return (
								// give time in secconds
								<option key={i} value={time.unix()}>
									Start at {time.format('HH:mm')}
								</option>
							);
						})}
					</Input>
					<br />
					{value === 'custom' && (
						<>
							<i className="fa-solid fa-comment" />
							&nbsp;Custom Message:
							<Input type="textarea" value={customMessage} onChange={(e) => setCustomMessage(e.target.value)} />
						</>
					)}
					<br />
					{!participation && (
						<strong className="text-danger mt-2">
							<i className="fas fa-exclamation-triangle fa-fw" /> This will not affect existing cancelled pilots!
						</strong>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setIsOpen(false)}>Cancel</Button>
					<LoadingButton loading={saving} onClick={handleSave} color="primary">
						Save
					</LoadingButton>{' '}
				</ModalFooter>
			</Modal>
		</>
	);
}
