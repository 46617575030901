import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';

function AddVoucherModal({ activities, children }) {
	const queryClient = useQueryClient();
	const [modal, setModal] = useState(false);

	const toggle = () => {
		reset();
		setModal(!modal);
	};

	const [activity, setActivity] = useState();
	const [codes, setCodes] = useState(1);
	const [expireDate, setExpireDate] = useState(moment().add(1, 'year').format('YYYY-MM-DD'));
	const [note, setNote] = useState();
	const [error, setError] = useState();
	const [createdCodes, setCreatedCodes] = useState();

	const reset = () => {
		setActivity();
		setCodes(1);
		setExpireDate(moment().add(1, 'year').format('YYYY-MM-DD'));
		setNote();
		setError();
		setCreatedCodes();
	};

	const add = async () => {
		try {
			setError();
			const data = await graphql({
				query: `
				mutation($input: VoucherInput!) {
					createVoucher(input: $input)
				}
			`,
				variables: {
					input: {
						activity,
						count: codes,
						expireDate,
						note
					}
				}
			});
			console.log(data);
			setCreatedCodes(data.createVoucher);
			await queryClient.invalidateQueries('vouchers');
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Create Codes</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}

					{createdCodes && (
						<Alert color="success">
							<h6>Codes Created</h6>
							{createdCodes.map((code) => (
								// code with copy button
								<div key={code}>
									{code}&nbsp;
									<Button
										onClick={() => {
											navigator.clipboard.writeText(code);
										}}
										color="primary"
										size="sm"
										outline
									>
										<i className="fa fa-copy"></i>
									</Button>
								</div>
							))}
						</Alert>
					)}

					<FormGroup floating>
						<Input
							id="exampleEmail"
							name="activity"
							placeholder="activity"
							type="select"
							value={activity}
							onChange={(e) => setActivity(e.target.value)}
						>
							<option value="">Select Activity</option>
							{activities.map((activity) => (
								<option key={activity.id} value={activity.id}>
									{activity.name}
								</option>
							))}
						</Input>
						<Label for="exampleEmail">Activity</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="codes"
							name="codes"
							placeholder="codes"
							type="number"
							value={codes}
							onChange={(e) => setCodes(Number(e.target.value))}
						/>
						<Label for="codes">How Many Codes</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="expireDate"
							name="expireDate"
							placeholder="expireDate"
							type="date"
							value={expireDate}
							onChange={(e) => setExpireDate(e.target.value)}
						/>
						<Label for="expireDate">Expire Date</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="note"
							name="note"
							placeholder="note"
							type="textarea"
							value={note}
							onChange={(e) => setNote(e.target.value)}
						/>
						<Label for="note">Note</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={add}>
						Add
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddVoucherModal;
