import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import LoadingButton from 'src/components/LoadingButton';

function AddParticipationModal({ personId, children, ...args }) {
	const queryClient = useQueryClient();
	const [modal, setModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);

	const [activity, setActivity] = useState('');
	const [bookedDate, setBookedDate] = useState(null);
	const [notes, setNotes] = useState('');
	const [quantity, setQuantity] = useState(1);

	const toggle = () => {
		if (modal) {
			setActivity('');
			setBookedDate(null);
			setNotes('');
			setQuantity(1);
			setError();
		}

		setModal(!modal);
	};

	const { data, isLoading } = useQuery(['AddParticipationModal'], async () => {
		const { activity } = await graphql(
			{
				query: `#graphql
					{
						activity(where:{product:{visible:{_eq:true}}}) {
							id
							name
						}
					}`
			},
			true
		);
		return { activity };
	});

	const save = async () => {
		try {
			setError();
			setSaving(true);

			// run this with the quantity
			for (let i = 0; i < quantity; i++) {
				await graphql({
					query: `#graphql
						mutation($input: ParticipationInput!) {
							saveParticipation(input: $input)
						}
						`,
					variables: {
						input: {
							activity: activity,
							date: i === 0 ? bookedDate : null,
							note: notes,
							personId
						}
					}
				});
			}
			setSaving(false);
			toggle();
			queryClient.invalidateQueries(['person', personId]);
		} catch (error) {
			setSaving(false);
			console.log(error);
			setError(error.message);
		}
	};

	if (isLoading) return <Spinner />;
	return (
		<div>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Add Participation</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}

					<FormGroup floating>
						<Input
							onChange={(e) => setActivity(e.target.value)}
							value={activity}
							id="activity"
							placeholder="activity"
							type="select"
						>
							<option value="">Select Activity</option>
							{data.activity
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((e) => (
									<option key={e.id} value={e.id}>
										{e.name}
									</option>
								))}
						</Input>
						<Label for="activity">Activity</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							onChange={(e) => setBookedDate(e.target.value)}
							value={bookedDate}
							id="bookedDate"
							placeholder="bookedDate"
							type="date"
						/>
						<Label for="bookedDate">Booked Date</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							onChange={(e) => setNotes(e.target.value)}
							value={notes}
							id="notes"
							placeholder="notes"
							type="textarea"
						/>
						<Label for="notes">Notes</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							onChange={(e) => {
								if (e.target.value > 5) return;
								if (e.target.value < 1) return;
								setQuantity(e.target.value);
							}}
							value={quantity}
							id="quantity"
							placeholder="Quantity"
							max={5}
							min={1}
							type="number"
						/>
						<Label for="quantity">Quantity</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<LoadingButton loading={saving} color="primary" onClick={save}>
						Add Participation
					</LoadingButton>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddParticipationModal;
