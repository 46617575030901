import { useEffect, useState, useCallback } from 'react';
import { Input, Row, Col, Button } from 'reactstrap';
import EditActivity from './EditActivity';
import Spinner from 'src/components/Spinner';
import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';
import EditProduct from 'src/components/EditProduct';
import { AskText } from 'src/components/AskComponents';
const Activities = () => {
	const [activities, setActivities] = useState([]);
	const [filteredActivities, setFilteredActivities] = useState([]);
	const [search, setSearch] = useState('');

	//   const [focusedActivity, SetFocusedActivity] = useState(null);

	const fetchData = useCallback(async (vars) => {
		var query = `#graphql
				query fetchData {
						
					activity(orderBy: {id: ASC}) {
						id
						participationCount
						product {
							id
							name
							description
							imageObject {
								url
							}
							price
							visible
						}
						sportBySportId {
							name
						}
					}
					
				}
			`;

		const data = await graphql({ query }, true);
		setActivities(data.activity);
		setFilteredActivities(data.activity);
	}, []);
	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const updateParticipationCount = async (activity, count) => {
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($id:bigint!,$input:ActivitySetInput!){
						updateActivityByPk(pkColumns:{id:$id},_set:$input){
							id
						}
					}
				`,
					variables: {
						id: activity.id,
						input: {
							participationCount: count
						}
					}
				},
				true
			);
			fetchData();
		} catch (error) {
			alert(error.message);
		}
	};

	// Search box filtering
	useEffect(() => {
		setFilteredActivities(
			activities.filter((q) => {
				if (q.product.name.toLowerCase().includes(search.toLowerCase())) return q;
				return false;
			})
		);
	}, [search, activities]);

	const createProduct = async (activity) => {
		try {
			const { saveProduct } = await graphql({
				query: `#graphql
					mutation ($input: ProductInput!) {
						saveProduct(input: $input) 
					}
				`,
				variables: {
					input: {
						name: activity.name,
						description: activity.description,
						price: activity.price,
						giftable: true
					}
				}
			});
			await graphql({
				query: `#graphql
					mutation ($input: ActivityInput!) {
						saveActivity(input: $input)
					}
				`,
				variables: {
					input: {
						id: activity.id,
						productId: saveProduct
					}
				}
			});
			fetchData();
		} catch (error) {
			alert(error.message);
		}
	};

	//   if (!token) return <Login setToken={setToken} />;

	const new_activity_data = {
		id: 0,
		name: '',
		description: '',
		price: '',
		participation_count: 1,
		activity_image: '',
		activity_hidden: 0,
		discounts: []
	};

	if (!activities.length) return <Spinner>Loading Activities...</Spinner>;
	else
		return (
			<>
				<h1 className="my-4">
					<i className="fas fa-paper-plane mr-2"></i>
					Activities
					<EditActivity activity_data={new_activity_data} activities={activities} onDone={fetchData}>
						<i style={{ cursor: 'pointer' }} className="fa fa-plus-circle float-right mr-4 mt-2"></i>
					</EditActivity>
				</h1>
				<Input onChange={(e) => setSearch(e.target.value)} className="mb-1" placeholder="Search ..." />
				{console.log(filteredActivities)}
				{filteredActivities.map((a) => {
					return (
						<Row key={a.id} className="mb-1">
							<Col sm={4}>
								<img className="img-fluid rounded" src={a.product.imageObject?.url} alt={a.product.name} />
							</Col>
							<div className="col-sm-8">
								<h3>
									{a.product.name} {!a.product.visible && '(HIDDEN)'}
									{/* <EditActivity activity_data={a} activities={activities} /> */}
									{a.product && <EditProduct id={a.product.id} onSubmit={fetchData} />}
									{!a.product && <Button onClick={() => createProduct(a)}>Create Product</Button>}
								</h3>
								ID: <b>ACT#{a.id}</b>
								<br />
								Product ID: <b>{a.product.id}</b>
								<br />
								Priced at <b>£{a.product.price}</b>
								<br />
								Can book
								<AskText
									type="number"
									value={a.participationCount}
									onSubmit={(count) => updateParticipationCount(a, count)}
									title="Participation Count"
									description="How many days should a user get for this activity?"
								>
									<b
										style={{
											color: '#0d6efd',
											textDecoration: 'underline'
										}}
									>
										{a.participationCount > 1 ? a.participationCount + ' days' : 'Single day only'}
									</b>
								</AskText>
								<div className="activity-description">{a.product.description}</div>
							</div>
						</Row>
					);
				})}
			</>
		);
};

export default Activities;
