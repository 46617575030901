import React, { useState, useEffect, useCallback } from 'react';
import { Table, Tooltip, Button, Badge, FormGroup, Input, Label } from 'reactstrap';
import axios from 'src/utils/axios';
import Spinner from 'src/components/Spinner';
import moment from 'moment';
import RegistrationsModal from './RegistrationsModal';
import { useApp } from 'src/components/App/appContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import { AskDate, AskText } from 'src/components/AskComponents';

export default function ThermalChasers() {
	const queryClient = useQueryClient();
	const { data, isLoading, error } = useQuery(['thermalChasers'], () => {
		const data = graphql({
			query: `
			{
				upcomingThermalChasers {
					id
					startDate
					endDate
					title
					ukOnly
					fullyBooked
					registrations {
						person {
							id
							name
							email
							mobile
						}
					}
				}
			}`
		});
		return data;
	});

	const saveThermalChasers = async (values) => {
		await graphql({
			query: `
			mutation($input: ThermalChasersInput!) {
				saveThermalChaser(input: $input)
			}`,
			variables: {
				input: {
					...values
				}
			}
		});

		await queryClient.invalidateQueries('thermalChasers');
	};

	if (isLoading) return <Spinner />;
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h3 className="my-3">Thermal Chasers</h3>
				<AskDate
					title="Add Thermal Chaser"
					label="Please enter trip start date"
					onSubmit={(v) => saveThermalChasers({ startDate: v })}
				>
					<Button color="primary" size="sm">
						<i className="fa fa-plus" /> Add Trip
					</Button>
				</AskDate>
			</div>
			<Table>
				<tr>
					<td>Title / Location</td>
					<td>Fly Date</td>
					<td>Return Date</td>
					<td>Registrations</td>
					<td>Fully Booked</td>
				</tr>
				{data.upcomingThermalChasers.map((t, i) => {
					return (
						<tr key={i}>
							<td>
								{t.title ? t.title : <Badge color="danger">No Title</Badge>}
								<AskText
									value={t.title}
									onSubmit={(v) =>
										saveThermalChasers({
											id: t.id,
											title: v
										})
									}
								/>
							</td>
							<td>
								{moment(t.startDate).format('DD/MM/YYYY')}&nbsp;
								<AskDate
									value={t.startDate}
									onSubmit={(v) =>
										saveThermalChasers({
											id: t.id,
											startDate: v
										})
									}
								/>
								<Badge color="info">{moment(t.startDate).fromNow()}</Badge>
							</td>
							<td>
								{moment(t.endDate).format('DD/MM/YYYY')}
								<AskDate
									value={t.endDate}
									onSubmit={(v) =>
										saveThermalChasers({
											id: t.id,
											endDate: v
										})
									}
								/>
							</td>
							<td>
								<RegistrationsModal registrations={t.registrations}>{t.registrations.length} View</RegistrationsModal>
							</td>
							<td>
								<FormGroup switch>
									<Input
										checked={t.fullyBooked}
										onClick={() =>
											saveThermalChasers({
												id: t.id,
												fullyBooked: !t.fullyBooked
											})
										}
										type="switch"
										role="switch"
									/>
									<Label check>{t.fullyBooked ? 'Yes' : 'No'}</Label>
								</FormGroup>
							</td>
						</tr>
					);
				})}
			</Table>
		</div>
	);
}
