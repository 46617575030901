import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Card, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import { useApp } from 'src/components/App/appContext';
import { AskConfirm } from 'src/components/AskComponents';
import graphql from 'src/utils/graphql';
import './index.css';
import Spinner from 'src/components/Spinner';

export default function DateSelectModal({
	activityId,
	participationId = null,
	updateBooking,
	children,
	setOpen,
	open
}) {
	const [monthIndex, setMonthIndex] = useState(0);
	const [error, setError] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [year, setYear] = useState(moment().year());
	const month = moment().add(monthIndex, 'month').format('MM');
	const [loading, setLoading] = useState(true);

	const toggle = () => setOpen(!open);

	const [availability, setAvailability] = useState(null);
	const fetch = useCallback(async () => {
		setLoading(true);
		const { workshopAvailability } = await graphql(
			{
				query: `#graphql
				query ($start:date!, $end: date!){
						workshopAvailability(where:{
							availabilityDate: {
								_gte: $start,
								_lte: $end
							},
						},orderBy:{availabilityDate:ASC}) {
							availabilityDate
							availabilityEndDate
							availableSpaces
						}					
					}`,
				variables: {
					start: `${year}-${month}-01`,
					end: moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD')
				}
			},
			true
		);

		setAvailability(workshopAvailability);
		setLoading(false);
	}, [activityId, monthIndex]);

	const save = async (date) => {
		try {
			await updateBooking({ date });
			setOpen(false);
		} catch (e) {
			setError(e.message);
		}
	};

	useEffect(() => {
		if (open) {
			fetch();
		}
	}, [open, fetch, month]);

	return (
		<>
			<span onClick={toggle}>{children || 'View Booking'}</span>
			<Modal size="" isOpen={open} toggle={toggle}>
				<ModalHeader
					toggle={toggle}
					close={
						<>
							<div style={{ paddingLeft: '20px' }}>
								<Button
									outline
									color="primary"
									onClick={() => setMonthIndex(monthIndex - 1)}
									disabled={monthIndex === 0}
								>
									<i class="fa-solid fa-arrow-left"></i>
								</Button>
								<div style={{ display: 'inline-block', margin: '0 5px' }} />
								<Button outline color="primary" onClick={() => setMonthIndex(monthIndex + 1)}>
									<i class="fa-solid fa-arrow-right"></i>
								</Button>
							</div>

							<Button close outline color="primary" onClick={toggle} />
						</>
					}
				>
					{moment(`${year}-${month}-01`).format('MMM YYYY')}
				</ModalHeader>
				{availability && !loading ? (
					<ModalBody>
						{error && <Alert color="danger">{error}</Alert>}
						<ListGroup flush style={{ overflowY: 'auto', maxHeight: '500px' }}>
							{availability.map(({ availabilityDate, availableSpaces }) => {
								// if day has passed continue
								if (moment(availabilityDate).isBefore(moment().add(1, 'day'), 'day')) return null;

								return (
									<ListGroupItem
										style={{ display: 'inline-block' }}
										onClick={() => availableSpaces && save(availabilityDate)}
									>
										<div
											className={availableSpaces ? 'row availableDay' : 'row'}
											style={
												availableSpaces
													? {
															cursor: 'pointer',
															'&:hover': {
																backgroundColor: '#f5f5f5'
															}
													  }
													: { cursor: 'not-allowed' }
											}
										>
											<div className="col-2" style={{ textAlign: 'center' }}>
												<span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
													{moment(availabilityDate).format('D')}
												</span>
												<br />
												{moment(availabilityDate).format('ddd')}
											</div>
											<div className="col-10" style={{ width: '50%' }}>
												{availableSpaces ? (
													<span style={{ color: 'green' }}>Spaces available</span>
												) : (
													<span style={{ color: 'red' }}>No spaces available </span>
												)}
											</div>
										</div>
									</ListGroupItem>
								);
							})}
							{/* next month item */}
							<ListGroupItem style={{ display: 'inline-block' }} onClick={() => setMonthIndex(monthIndex + 1)}>
								<div className="row" style={{ cursor: 'pointer' }}>
									<div className="col-2" style={{ textAlign: 'center' }}>
										<span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
											<i class="fa-solid fa-arrow-right"></i>
										</span>
									</div>
									<div className="col-10" style={{ width: '50%' }}>
										Go to next month
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</ModalBody>
				) : (
					<ModalBody>
						<Spinner noTransform />
					</ModalBody>
				)}
			</Modal>
		</>
	);
}
