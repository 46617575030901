import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import { AskFile } from 'src/components/AskComponents';
import graphql from 'src/utils/graphql';

function CreateInvoiceModal({ children, ...args }) {
	const queryClient = useQueryClient();
	const { user } = useApp();

	const [modal, setModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);

	const toggle = () => {
		if (modal) resetModal();
		setModal(!modal);
	};

	const [payeeName, setPayeeName] = useState(user?.name || '');
	const [notes, setNotes] = useState('');
	const [bankDetails, setBankDetails] = useState('');
	const [price, setPrice] = useState(0);
	const [file, setFile] = useState();

	const resetModal = () => {
		setPayeeName(user?.name || '');
		setNotes('');
		setBankDetails('');
		setPrice(0);
		setFile();
		setSaving(false);
		setError();
	};

	const createInvoice = async () => {
		if (saving) return;
		setSaving(true);
		setError();
		try {
			await graphql(
				{
					query: `
					mutation ($input:PersonInvoiceInsertInput!){
						insertPersonInvoiceOne(object:$input) {
							id
						}
					}`,
					variables: {
						input: {
							bankDetails,
							filename: file?.name,
							key: file?.id,
							mime: file?.type,
							notes: notes,
							payeeName,
							value: price,
							personId: user.id
						}
					}
				},
				true
			);
			resetModal();
			toggle();
			queryClient.invalidateQueries('invoices');
		} catch (e) {
			console.error(e);
			setError(e.message);
		} finally {
			setSaving(false);
		}
	};

	return (
		<div>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Crate Invoice</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					<FormGroup floating>
						<Input
							id="payeeName"
							name="payeeName"
							placeholder="payeeName"
							type="text"
							value={payeeName}
							onChange={(e) => setPayeeName(e.target.value)}
						/>
						<Label for="payeeName">Payee Name</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="Notes"
							name="Notes"
							placeholder="Notes"
							type="textarea"
							value={notes}
							onChange={(e) => setNotes(e.target.value)}
						/>
						<Label for="Notes">Notes for completed work</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="bankDetails"
							name="bankDetails"
							placeholder="bankDetails"
							type="textarea"
							value={bankDetails}
							onChange={(e) => setBankDetails(e.target.value)}
						/>
						<Label for="bankDetails">Bank Details</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							id="price"
							name="price"
							placeholder="price"
							type="float"
							value={price}
							onChange={(e) => setPrice(e.target.value)}
						/>
						<Label for="price">Invoice Price</Label>
					</FormGroup>

					<AskFile title="Upload Invoice" onSubmit={(file) => setFile(file)}>
						<Button outline={file} style={{ width: '100%' }} color="primary">
							{file ? file.name : 'Upload Invoice'}
						</Button>
					</AskFile>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={createInvoice}>
						Create
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default CreateInvoiceModal;
