import React, { useState } from 'react';

export const CartContext = React.createContext();

export function CartProvider(props) {
	// get cart from local storage

	const [items, setItems] = useState(JSON.parse(localStorage.getItem('cart')) || []);
	const [codes, setCodes] = useState(JSON.parse(localStorage.getItem('codes')) || []);

	// Functions to update state and local storage
	const saveCart = (cart) => {
		localStorage.setItem('cart', JSON.stringify(cart));
		setItems(cart);
	};

	const saveCodes = (codes) => {
		localStorage.setItem('codes', JSON.stringify(codes));
		setCodes(codes);
	};

	const addItem = (id, gift = false) => {
		// check if item is already in cart
		// const isItemInCart = items.some((cartItem) => cartItem.id === id && cartItem.gift === gift);

		// if (isItemInCart) {
		// 	// if item is already in cart, increase quantity by 1
		// 	saveCart(
		// 		items.map((cartItem) =>
		// 			cartItem.id === id && cartItem.gift === gift ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
		// 		)
		// 	);
		// } else {
		// if item is not in cart, add it
		saveCart([...items, { id, quantity: 1, gift }]);
		// }
	};

	const removeItem = (id, gift) => {
		const index = items.findIndex((item) => item.id === id && item.gift === gift);
		if (index !== -1) {
			const newItems = [...items];
			newItems.splice(index, 1);
			saveCart(newItems);
		}
	};

	const toggleGift = (id, gift) => {
		const index = items.findIndex((item) => item.id === id && item.gift === gift);
		if (index !== -1) {
			const newItems = [...items];
			newItems[index] = { ...newItems[index], gift: !gift };
			saveCart(newItems);
		}
	};

	const clearCart = () => {
		saveCart([]);
	};

	const addCode = (code) => {
		saveCodes([...codes, code]);
	};

	const updateItemQuantity = (id, quantity, gift) => {
		if (quantity < 1) return removeItem(id, gift);
		saveCart(items.map((item) => (item.id === id && item.gift === gift ? { ...item, quantity } : item)));
	};

	const removeCode = (code) => {
		saveCodes(codes.filter((item) => item !== code));
	};

	const clearCodes = () => {
		saveCodes([]);
	};
	if (!props) return null;
	return (
		<CartContext.Provider
			value={{
				items,
				codes,
				addItem,
				removeItem,
				clearCart,
				updateItemQuantity,
				addCode,
				removeCode,
				clearCodes,
				CartProvider,
				toggleGift
			}}
		>
			{props.children}
		</CartContext.Provider>
	);
}
