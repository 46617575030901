import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Form } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';

function AddDiscountCode({ children, onSubmit, personId }) {
	const [modal, setModal] = useState(false);
	const [error, setError] = useState(null);

	const toggle = () => setModal(!modal);

	const { data, isLoading } = useQuery(['discountCodes'], async () => {
		return await graphql(
			{
				query: `#graphql
				{
					discountCode(where: {
						singleUse: {_eq: true}
						expiry: {_gte: "now()"}
					}) {
						id
						name
					}
				}
			`
			},
			true
		);
	});

	const [discountId, setDiscountId] = useState(null);

	const submit = async () => {
		try {
			if (!discountId) throw new Error('Please select a discount code');
			setError();

			await graphql(
				{
					query: `#graphql
					mutation($input: PersonDiscountCodeInsertInput!) {
						insertPersonDiscountCodeOne(object: $input) {
							id
						}
					}
					`,
					variables: {
						input: {
							personId,
							discountCodeId: discountId
						}
					}
				},
				true
			);
			if (onSubmit) await onSubmit();

			toggle();

			// Reset
			setDiscountId(null);
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div>
			<div color="danger" onClick={toggle}>
				{children || 'Open Modal'}
			</div>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Add Completion</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					{isLoading ? (
						<Spinner />
					) : (
						<>
							<small>Only single-use discount codes that have not expired will be shown.</small>
							<br />
							<FormGroup floating>
								<Input
									onChange={(e) => setDiscountId(e.target.value)}
									value={discountId}
									id="discountId"
									placeholder="discountId"
									type="select"
								>
									<option value="">Select a discount code</option>
									{data.discountCode
										.sort((a, b) => a.name.localeCompare(b.name))
										.map((discountCode) => (
											<option value={discountCode.id}>
												{discountCode.name} ({discountCode.id})
											</option>
										))}
								</Input>
								<Label for="discountId">Discount Code</Label>
							</FormGroup>
						</>
					)}
				</ModalBody>

				<ModalFooter>
					<Button color="primary" onClick={submit}>
						Add
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddDiscountCode;
