import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import axios from 'src/utils/axios';
import Spinner from 'src/components/Spinner';
import { useNavigate } from 'react-router-dom';
import { useApp } from 'src/components/App/appContext';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import ClientLink from 'src/components/ClientLink';
import ProductModal from 'src/components/ProductModal';
import styled from 'styled-components';
import ServiceModal from './ServiceModal';

const StyledTableContainer = styled.div`
	overflow-x: auto;
	@media (min-width: 768px) {
		overflow-x: visible;
	}
`;

export default function Servicing() {
	const navigate = useNavigate();
	const { user } = useApp();

	const { data, isLoading, error } = useQuery(['myWorkshopBookings'], async () => {
		const data = await graphql(
			{
				query: `#graphql
				{
					me {
						workshopBookings(orderBy: {id: DESC}) {
							id
							workshopBookingService {
     							product {
        							id
        							name
      							}
    						}
							status {
								id
								name
							}
							date
						}
					}
					workshopService(orderBy: {listOrder: ASC},where:{product:{visible:{_eq:true}}}) {
						serviceId
						product {
							id
							description
							name
							price
							imageObject {
								url
							}
						}
					}
				}
			`
			},
			true
		);
		let me = data.me[0];
		return { me, workshopService: data.workshopService };
	});
	console.log(data);

	useEffect(() => {
		if (!user) {
			navigate('/');
		}
	}, [user, navigate]);

	if (isLoading) return <Spinner />;
	return (
		<div>
			{data.me.workshopBookings.length > 0 ? (
				<StyledTableContainer>
					<h3 className="mt-5">My Workshop Bookings</h3>
					<Table hover className="mt-2">
						<thead>
							<tr>
								<th>Booking ID</th>
								<th>Service</th>
								<th>Status</th>
								<th>Drop Off Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data.me.workshopBookings &&
								data.me.workshopBookings.map((b, i) => {
									return (
										<tr key={i}>
											<td>{b.id}</td>
											<td>{b.workshopBookingService?.product?.name || 'Old Booking'}</td>
											<td>{b.status.name}</td>
											<td>{b.date ? moment(b.date).format('DD/MM/YYYY') : 'Not Booked'}</td>
											<td>
												{b.date ? (
													<Button color="primary" onClick={() => navigate(`/servicing/` + b.id)}>
														View
													</Button>
												) : (
													<Button color="primary" onClick={() => navigate(`/servicing/` + b.id)}>
														Complete Info & Book
													</Button>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</StyledTableContainer>
			) : (
				''
			)}
			<h3 className="mt-5">Workshop Services</h3>
			{data.workshopService.map((s, i) => {
				return (
					<>
						{console.log('full service', s)}
						<ServiceModal key={s.product.id} service={s}>
							<ClientLink title={s.product.name} text={s.product.description} icon="fas fa-wrench" />
						</ServiceModal>
					</>
				);
			})}
		</div>
	);
}
