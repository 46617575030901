import React from 'react';
import ClientLink from '../../../components/ClientLink';
import CodeChecker from 'src/components/CodeChecker';
import { useApp } from 'src/components/App/appContext';
import Spinner from 'src/components/Spinner';
import { AskLogin } from 'src/components/AskComponents';

export default function Homepage() {
	const { user, isLoaded } = useApp();

	// if (!isLoaded) return <Spinner />;
	return (
		<div>
			<CodeChecker title="Redeem Gift Voucher">
				<ClientLink
					title="Redeem Gift Code"
					icon="fa fa-fw fa-gift"
					text="Have you been sent a gift code? Click to use!"
				/>
			</CodeChecker>
			<ClientLink
				title="Purchase Course / Trial Day"
				icon="fa fa-fw fa-ticket-alt"
				text="See availability and purchase trial days / courses for yourself or as a gift"
				to="/choose-sport"
			/>
			<AskLogin>
				<ClientLink
					title="My Bookings"
					icon="fa fa-fw fa-calendar"
					text="View your bookings, change or cancel dates"
					to={user ? '/bookings' : null}
				/>
			</AskLogin>
			{/* <ClientLink
				title="Thermal Chasers"
				icon="fa fa-fw fa-plane"
				text="View dates and register interest in our thermal chaser trips"
				to="/thermal-chasers"
			/> */}
			<AskLogin>
				<ClientLink
					title="Servicing & Workshop"
					icon="fa fa-fw fa-wrench"
					text="Parachute repacking, glider inspections, click here for more info"
					to={user ? '/servicing' : null}
				/>
			</AskLogin>
			<ClientLink
				title="Club Membership"
				icon="fas fa-user-tie"
				text="Join the club and get access to our facilities and events"
				to="/membership"
			/>
		</div>
	);
}
