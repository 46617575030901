import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Alert } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import LoadingButton from 'src/components/LoadingButton';
import { AskConfirm } from 'src/components/AskComponents';

export default function ParticipationModal({ participationId, onSubmit, children }) {
	const [state, setState] = useState(null);
	const [saving, setSaving] = useState(false);
	const [statuses, setStatuses] = useState(null);
	const [modal, setModal] = useState(false);
	const [error, setError] = useState(null);

	const toggle = () => setModal(!modal);

	const handleSave = async () => {
		setError(null);
		setSaving(true);
		try {
			await graphql({
				query: `#graphql
					mutation ($input:ParticipationInput!){
						saveParticipation(input:$input)
					}
				`,
				variables: {
					input: {
						...state,
						date: state.date === '' ? null : state.date
					}
				}
			});

			await onSubmit();
			setModal(false);
		} catch (error) {
			setError(error.message);
			console.log(error);
		} finally {
			setSaving(false);
		}
	};

	const creditDay = async (id) => {
		await graphql({
			query: `#graphql
					mutation ($id:ID!){
						creditParticipation(id:$id)
					}
				`,
			variables: {
				id
			}
		});
		const newState = { ...state };
		newState.pilotUsedRebook = true;
		setState(newState);
	};

	const handleChange = (e) => {
		let { name, value, checked } = e.target;

		switch (name) {
			case 'startTime':
				const [hours, minutes] = value.split(':');
				value = Number(parseInt(hours) * 3600 + parseInt(minutes) * 60);
				break;

			case 'startCheckAm':
				value = checked;
				break;

			case 'pilotUsedRebook':
				value = checked;
				break;

			case 'statusId':
				value = parseInt(value);
				break;

			default:
				break;
		}

		setState({ ...state, [name]: value });
	};

	const { data, isLoading } = useQuery(
		['participationModal'],
		async () => {
			try {
				const { participationByPk, participationStatus } = await graphql(
					{
						query: `query ($participationId:bigint!){
						participationByPk(id:$participationId) {
							id
							date
							statusId
							startTime
							pilotUsedRebook
							startCheckAm
							expiresAt
						}
						participationStatus {
							id
							name
						}
					}`,
						variables: {
							participationId
						}
					},
					true
				);
				setState(participationByPk);
				setStatuses(participationStatus);
				return participationByPk;
			} catch (error) {
				setError(error.message);
			}
		},
		{ enabled: modal }
	);

	function secondsToTime(seconds) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const pad = (num) => (num < 10 ? '0' : '') + num;
		return `${pad(hours)}:${pad(minutes)}`;
	}

	return (
		<div>
			{children ? (
				<span onClick={toggle}>{children}</span>
			) : (
				<Button size="sm" color="primary" outlined onClick={toggle}>
					Edit
				</Button>
			)}
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Manage Participation</ModalHeader>
				<ModalBody>
					{isLoading || !state ? (
						<Spinner />
					) : (
						<>
							{error && <Alert color="danger">{error}</Alert>}
							<FormGroup floating>
								<Input id="statusId" type="select" name="statusId" value={state.statusId} onChange={handleChange}>
									{statuses &&
										statuses.map((status) => (
											<option key={status.id} value={status.id}>
												{status.name}
											</option>
										))}
								</Input>
								<Label for="statusId">Status</Label>
							</FormGroup>

							<FormGroup floating>
								<Input id="date" type="date" name="date" value={state.date} onChange={handleChange} />
								<Label for="date">Date</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									id="expiresAt"
									type="datetime-local"
									name="expiresAt"
									//Split to remove milliseconds and seconds
									value={
										state.expiresAt.split('.')[0].split(':')[0] + ':' + state.expiresAt.split('.')[0].split(':')[1]
									}
									onChange={handleChange}
								/>
								<Label for="date">Expiry Date</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									id="startTime"
									type="time"
									name="startTime"
									value={secondsToTime(state.startTime)}
									onChange={handleChange}
								/>
								<Label for="startTime">Start Time</Label>
							</FormGroup>

							<FormGroup switch>
								<Input
									id="startCheckAm"
									type="switch"
									name="startCheckAm"
									checked={state.startCheckAm}
									onChange={handleChange}
								/>
								<Label for="startCheckAm">Start Check AM</Label>
							</FormGroup>

							{state.pilotUsedRebook ? (
								<div className="mt-2" style={{ color: 'green', fontWeight: 'bold' }}>
									<i className="fa fa-check" /> Additional day has been credited
								</div>
							) : (
								<>
									<div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>
										<i className="fa fa-times" /> Additional day has NOT been credited
									</div>
									<AskConfirm
										title="Are you sure?"
										description="This will credit a new day"
										onSubmit={() => creditDay(state.id)}
									>
										<Button color="secondary">Credit new day</Button>
									</AskConfirm>
								</>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<LoadingButton loading={saving} color="primary" onClick={handleSave}>
						Save
					</LoadingButton>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
