const query = `
{
  me {
    participations {
      id
      date
	  canPilotCancel
	  expired
      activity {
        id
        name
		description
        product {
          id
          name
          description
          price
          giftable
          imageObject {
            url
          }
        }
        sportBySportId {
          id
          name
        }
      }
      status {
        id
        name
      }
    } 
  }
}
`;
module.exports = query;
