import * as React from 'react';
import axios from 'src/utils/axios';
import graphql from 'src/utils/graphql';
const AppContext = React.createContext();

const personFields = `#graphql
id
name
firstName
lastName
email
mobile
weight
height
dob
gender
admin
referralSource
#dashboard
personDiscountCodes {
	discountCode {
		id
		name
	}
}
profileComplete
grantedPermits {
	permit {
		id
		name
	}
}
bhpaNumber
bhpaExpiry
`;

const { dashboardGroups } = require('./AdminDashboard');

function AppProvider({ children }) {
	const [accessToken, setAccessToken] = React.useState(localStorage.getItem('accessToken') || null);
	const [settings, setSettingsValues] = React.useState(JSON.parse(localStorage.getItem('settings')));

	const [user, setUser] = React.useState(null);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [error, setError] = React.useState(null);

	React.useEffect(() => {
		if (!accessToken) return setIsLoaded(true);
		localStorage.setItem('accessToken', accessToken);
		if (!user) fetchUser();
	}, [accessToken]);

	const login = async (email, password) => {
		const { login } = await graphql({
			query: `#graphql
				mutation($email: String!, $password: String!) {
					login(email: $email, password: $password) {
						token
					}
				}`,
			variables: {
				email,
				password
			}
		});
		setUser(login.person);
		console.log(login.person);
		setAccessToken(login.token);
	};

	const resetPassword = async (resetToken, password) => {
		const { resetPassword } = await graphql({
			query: `#graphql
				mutation($token: String!, $password: String!) {
					resetPassword(token: $token, password: $password)
				}
				`,
			variables: {
				token: resetToken,
				password
			}
		});
		return resetPassword;
	};

	const register = async (firstName, lastName, email, password) => {
		const { register } = await graphql({
			query: `#graphql
				mutation($input: PersonInput!) {
					register(input: $input) {
						token
					}
				}`,
			variables: {
				input: {
					firstName,
					lastName,
					email,
					password
				}
			}
		});
		setUser(register.person);
		setAccessToken(register.token);
	};

	const fetchUser = async () => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
		try {
			setError(null);
			setIsLoaded(false);
			const data = await graphql(
				{
					query: `{me {${personFields}}}`
				},
				true
			);

			const [me] = data.me;

			if (me) me.dashboard = dashboardGroups;
			// put all permit names into an array
			if (me) me.permits = me.grantedPermits.map((p) => p.permit.name);

			setUser(me);
		} catch (err) {
			// Bad token, failed.
			setAccessToken(null);
			setError(err.message);
		} finally {
			setIsLoaded(true);
		}
	};

	const setSettings = (val) => {
		localStorage.setItem('settings', JSON.stringify(val));
		setSettingsValues(val);
	};

	const value = {
		isLoaded,
		settings,
		setSettings: setSettings,
		user,
		logout: () => {
			localStorage.removeItem('accessToken');
			axios.defaults.headers.common['Authorization'] = null;
			setAccessToken(null);
			setUser(null);
		},
		login,
		resetPassword,
		register,
		setAccessToken,
		fetchUser,
		error
	};
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
function useApp() {
	const context = React.useContext(AppContext);
	if (context === undefined) {
		throw new Error('useApp must be used within a AppProvider');
	}
	return context;
}
export { AppProvider, useApp };
