import React, { useState, useCallback, useEffect } from 'react';
import graphql from 'src/utils/graphql';
import { Table, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import { AskFile, AskSelect, AskText, AskDate } from 'src/components/AskComponents';
import { useApp } from 'src/components/App/appContext';
import LoginFallback from './loginFallback';
import moment from 'moment';

export default function Profile() {
	const [profile, setProfile] = useState(null);
	const { user, fetchUser } = useApp();

	const fetchProfile = useCallback(async () => {
		let { me } = await graphql(
			{
				query: `{
						me {
							id
							name
							firstName
							lastName
							email
							weight
							height
							dob
							avatarObject {
								url
							}
							gender
							mobile
							profileComplete
							emailMarketing
							bhpaNumber
							bhpaExpiry
							participations(orderBy: {date: DESC}) {
								id
								status {
									name
								}
								date
								activity {
									id
									name
								}
							}
						}
					}
				`
			},
			true
		);
		me = me[0];

		setProfile(me);
	}, []);

	const updateProfile = useCallback(
		async (values) => {
			await graphql({
				query: `mutation ($input: PersonInput!) {
				savePerson(input: $input)
			}`,
				variables: {
					input: {
						...values,
						id: profile.id
					}
				}
			});
			await fetchProfile();
			await fetchUser(); //Refresh me
		},
		[fetchProfile, profile]
	);

	useEffect(() => {
		if (!user) return setProfile(null);
		fetchProfile();
	}, [fetchProfile, user]);

	if (!profile && !user) return <LoginFallback />;

	if (!profile || !user) return <Spinner />;

	return (
		<div>
			<div className="row">
				<div className="col-md-6">
					<h3>Profile</h3>
					{profile.profileComplete ? (
						<Alert style={{ padding: '7px' }} color="success">
							Your profile is complete. You can edit your profile below.
						</Alert>
					) : (
						<Alert style={{ padding: '7px' }} color="warning">
							Your profile is incomplete. Please complete your profile below.
						</Alert>
					)}
					<Table bordered>
						<tbody>
							<TableRow label="Avatar">
								<img src={profile?.avatarObject?.url} style={{ width: '100px' }} />
								<AskFile title="Avatar" onSubmit={(v) => updateProfile({ avatar: v.id })} />
							</TableRow>
							<TableRow label="Fist Name">
								{profile.firstName}&nbsp;
								<AskText
									title="First Name"
									value={profile.firstName}
									onSubmit={(v) => updateProfile({ firstName: v })}
								/>
							</TableRow>
							<TableRow label="Last Name">
								{profile.lastName}&nbsp;
								<AskText title="Last Name" value={profile.lastName} onSubmit={(v) => updateProfile({ lastName: v })} />
							</TableRow>
							<TableRow label="Email">
								{profile.email}&nbsp;
								{/* <AskText title="Email" value={profile.email} onSubmit={(v) => updateProfile({ email: v })} /> */}
							</TableRow>
							<TableRow label="Mobile">
								{profile.mobile}&nbsp;
								<AskText title="Mobile" value={profile.mobile} onSubmit={(v) => updateProfile({ mobile: v })} />
							</TableRow>
							<TableRow label="Height">
								{profile.height}&nbsp;
								<AskText title="Height" value={profile.height} onSubmit={(v) => updateProfile({ height: v })} />
							</TableRow>
							<TableRow label="Weight">
								{profile.weight}&nbsp;
								<AskText title="Weight" value={profile.weight} onSubmit={(v) => updateProfile({ weight: v })} />
							</TableRow>
							<TableRow label="Date of Birth">
								{new Date(profile.dob).toLocaleDateString('en-GB')}&nbsp;
								<AskDate title="Date of Birth" value={profile.dob} onSubmit={(v) => updateProfile({ dob: v })} />
							</TableRow>
							<TableRow label="Gender">
								{profile.gender ? 'Male' : 'Female'}&nbsp;
								<AskSelect
									title="Gender"
									value={profile.gender}
									values={[
										{
											text: 'Male',
											value: true
										},
										{
											text: 'Female',
											value: false
										}
									]}
									onSubmit={(v) => updateProfile({ gender: v === 'true' })}
								/>
							</TableRow>
							<TableRow label="Communication Settings">
								{profile.emailMarketing ? 'Yes' : 'No'}&nbsp;
								<AskSelect
									title="Communication Settings"
									description="Would you like to receive communication & offers from us?"
									value={profile.emailMarketing}
									values={[
										{
											text: 'Yes',
											value: true
										},
										{
											text: 'No',
											value: false
										}
									]}
									onSubmit={(v) => updateProfile({ emailMarketing: v === 'true' })}
								/>
							</TableRow>
						</tbody>
					</Table>

					<h3>BHPA</h3>
					<Table bordered>
						{/* BHPA number & expiry  */}
						<tbody>
							<TableRow label="BHPA Number">
								{profile.bhpaNumber || 'None'}&nbsp;
								<AskText
									title="BHPA Number"
									value={profile.bhpaNumber}
									onSubmit={(v) => updateProfile({ bhpaNumber: v })}
								/>
							</TableRow>
							<TableRow label="BHPA Expiry">
								{profile.bhpaExpiry && moment(profile.bhpaExpiry).isBefore(moment()) && (
									<Alert color="danger">Your BHPA has expired!</Alert>
								)}
								{profile.bhpaExpiry ? new Date(profile.bhpaExpiry).toLocaleDateString('en-GB') : 'None'}&nbsp;
								<AskDate
									title="BHPA Expiry"
									value={profile.bhpaExpiry}
									// check date valid if not use null
									onSubmit={(v) => {
										if (new Date(v).toString() === 'Invalid Date') {
											return updateProfile({ bhpaExpiry: null });
										}
										updateProfile({ bhpaExpiry: v });
									}}
								/>
							</TableRow>
						</tbody>
					</Table>
				</div>
				<div className="col-md-6">
					<h3>My Flights</h3>
					<Table bordered>
						<tbody>
							{profile.participations.map((p) => (
								<tr key={p.id}>
									<td>{p.activity.name}</td>
									<td>{p.date}</td>
									<td>{p.status.name}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
}

const TableRow = ({ label, children }) => (
	<tr>
		<td>{label}</td>
		<td>{children}</td>
	</tr>
);
