import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Card, Row, Col, Table, Button, Input, Label, CardHeader } from 'reactstrap';
import moment from 'moment';
import Spinner from 'src/components/Spinner';

import { AskDate, AskFile, AskSelect, AskText } from 'src/components/AskComponents';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import { useApp } from 'src/components/App/appContext';

const query = `#graphql
query ($id: bigint!)  {
  workshopBookingByPk(id: $id) {
    id
	date
	otherInfo
    equipmentMake
	equipmentModel
    equipmentSize
    equipmentSerialNumber
    equipmentDateOfManufacture
    equipmentDamage
	staffNote
	status{
      id
      name
    }
	bookingFiles {
      file{
        id
        url
		fileName
	  	uploadTimestamp
      }
    }
    personId
    person {
      firstName
      lastName
      email
      mobile
    }
	workshopBookingService {
      product {
        id
        name
      }
    }
  }
  workshopBookingStatus {
	id
	name
  }
}
`;

export default function WorkshopBooking() {
	let history = useNavigate();
	const { id } = useParams();
	const { user } = useApp();

	// const [emailTemplates, setEmailTemplates] = useState([]);
	// const [emailTemplate, setEmailTemplate] = useState([]);
	const [noteValue, setNoteValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const { data, isLoading, error, refetch } = useQuery(['workshopBooking', id], async () => {
		const { workshopBookingByPk, workshopBookingStatus } = await graphql(
			{
				query,
				variables: { id }
			},
			true
		);
		return { ...workshopBookingByPk, workshopBookingStatus };
	});
	console.log(data);

	const updateStatus = async (statusId) => {
		setLoading(true);
		try {
			await graphql(
				{
					query: `#graphql
				mutation ($id:bigint!,$input:WorkshopBookingSetInput){
					updateWorkshopBookingByPk(pkColumns:{id:$id},_set:$input) {
						id
					}
				}`,
					variables: {
						id,
						input: {
							statusId
						}
					}
				},
				true
			);

			setIsSuccess(true);
			await refetch();
		} catch (error) {
			console.error(error);
			// Handle error state if needed
		} finally {
			setLoading(false);
		}
	};

	const updateField = async (inputs) => {
		setLoading(true);
		try {
			await graphql(
				{
					query: `#graphql
				mutation ($id:bigint!,$input:WorkshopBookingSetInput){
					updateWorkshopBookingByPk(pkColumns:{id:$id},_set:$input) {
						id
					}
				}`,
					variables: {
						id,
						input: inputs
					}
				},
				true
			);

			setIsSuccess(true);
			await refetch();
		} catch (error) {
			console.error(error);
			// Handle error state if needed
		} finally {
			setLoading(false);
		}
	};

	// useEffect(() => {
	// 	if (!data) return;
	// 	setPerson(data.person);
	// }, [data]);

	// const emailPilot = () => {
	// 	if (emailTemplate) {
	// 		history.push(`/admin/emailer/${emailTemplate}/${id}`);
	// 	}
	// };

	const addStaffNote = async () => {
		await graphql(
			{
				query: `
			mutation ($id:bigint!,$input:WorkshopBookingSetInput){
				updateWorkshopBookingByPk(pkColumns:{id:$id},_set:$input) {
					id
				}
			}`,
				variables: {
					id,
					input: {
						staffNote: data.staffNote
							? `${data.staffNote}<p><b>${moment().format('DD/MM/YYYY')} - ${user.name}:</b> ${noteValue}</p>`
							: `<p><b>${moment().format('DD/MM/YYYY')} - ${user.name}:</b> ${noteValue}</p>`
					}
				}
			},
			true
		);
		setNoteValue('');
		await refetch();
	};

	const addFile = async (s3ObjectId) => {
		console.log('uploading file');
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($input: WorkshopBookingFileInsertInput!) {
						insertWorkshopBookingFileOne(object: $input){
							id
						}
					}
				`,
					variables: {
						input: {
							bookingId: id,
							s3ObjectId
						}
					}
				},
				true
			);

			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	if (isLoading && !data) return <Spinner />;
	return (
		<>
			<h2>Workshop Booking - #{id} </h2>
			<Row>
				<Col md="5">
					<Card>
						<Table>
							<tbody>
								<tr>
									<th>First Name</th>
									<td>{data.person.firstName}</td>
								</tr>
								<tr>
									<th>Last Name</th>
									<td>{data.person.lastName}</td>
								</tr>
								<tr>
									<th>Email</th>
									<td>{data.person.email}</td>
								</tr>
								<tr>
									<th>Phone</th>
									<td>{data.person.mobile}</td>
								</tr>
							</tbody>
						</Table>
					</Card>

					<Card className="mt-3">
						<CardHeader>
							<Label for="exampleSelect">
								Workshop Booking Status:{' '}
								<span style={{ padding: 5, fontWeight: 'bold', textAlign: 'center' }}>{data.status.name}</span>
							</Label>
						</CardHeader>

						<AskSelect
							value={data.status.id}
							onSubmit={(value) => updateStatus(value)}
							values={data.workshopBookingStatus.map((status) => ({
								value: status.id,
								text: status.name
							}))}
						>
							<Button color="primary" type="submit" block>
								Update Status & Email Pilot
							</Button>
						</AskSelect>
					</Card>

					<Card className="mt-3">
						<CardHeader>
							<Label for="exampleSelect">Workshop Service Report(s)</Label>
						</CardHeader>
						<AskFile title="Upload Image" onSubmit={(file) => addFile(file.id)}>
							<Button color="primary" block>
								Upload File
							</Button>
						</AskFile>
						<Table>
							<tbody>
								{data.bookingFiles.map((file) => (
									<tr key={file.file.id}>
										<td>
											<a href={file.file.url} target="_blank" rel="noopener noreferrer">
												{file.file.fileName} -{moment(file.file.uploadTimestamp).format('DD/MM/YYYY HH:mm')}
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card>
				</Col>
				<Col md="7">
					<Card>
						<Table>
							<tbody>
								<tr>
									<th>Service Booked</th>
									{data.workshopBookingService?.product.name ? (
										<td>{data.workshopBookingService.product.name}</td>
									) : (
										<td>N/A</td>
									)}
								</tr>
								<tr>
									<th>Drop Off Date</th>
									<td>
										{data.date && moment(data.date).format('DD/MM/YYYY')}&nbsp;
										<AskDate title="Drop Off Date" onSubmit={(value) => updateField({ date: value })} />
									</td>
								</tr>
								<tr>
									<th>Reason for Service</th>
									<td>
										{data.otherInfo}&nbsp;
										<AskText title="Reason for Service" onSubmit={(value) => updateField({ otherInfo: value })} />
									</td>
								</tr>
								<tr>
									<th>Damage to Equipment</th>
									<td>
										{data.equipmentDamage}&nbsp;
										<AskText
											title="Damage to Equipment"
											onSubmit={(value) => updateField({ equipmentDamage: value })}
										/>
									</td>
								</tr>
								<tr>
									<th>Equipment Manufacturer (PG/HG Service)</th>
									<td>
										{data.equipmentMake}&nbsp;
										<AskText title="Equipment Make" onSubmit={(value) => updateField({ equipmentMake: value })} />
									</td>
								</tr>
								<tr>
									<th>Equipment Model (PG/HG Service)</th>
									<td>
										{data.equipmentModel}&nbsp;
										<AskText title="Equipment Model" onSubmit={(value) => updateField({ equipmentModel: value })} />
									</td>
								</tr>
								<tr>
									<th>Equipment Size (PG/HG Service)</th>
									<td>
										{data.equipmentSize}&nbsp;
										<AskText title="Equipment Size" onSubmit={(value) => updateField({ equipmentSize: value })} />
									</td>
								</tr>
								<tr>
									<th>Serial Number (PG/HG Service)</th>
									<td>
										{data.equipmentSerialNumber.toUpperCase()}&nbsp;
										<AskText
											title="Serial Number"
											onSubmit={(value) => updateField({ equipmentSerialNumber: value })}
										/>
									</td>
								</tr>
								<tr>
									<th>Date of Manufacture (PG/HG Service)</th>
									<td>
										{data.equipmentDateOfManufacture &&
											moment(data.equipmentDateOfManufacture).format('MMM YYYY').toUpperCase()}
										&nbsp;
										<AskDate
											title="Date of Manufacture"
											onSubmit={(value) => updateField({ equipmentDateOfManufacture: value })}
										/>
									</td>
								</tr>
							</tbody>
						</Table>
					</Card>
				</Col>
				<Col md={12}>
					<Card className="mt-3">
						<CardHeader>
							<Label for="exampleSelect">Additional Staff Notes</Label>
						</CardHeader>
						<div
							style={{
								padding: '5px 15px',
								display: data.staffNote ? 'block' : 'none'
							}}
							dangerouslySetInnerHTML={{ __html: data.staffNote }}
						/>

						<Input
							type="textarea"
							// name="select"
							id="exampleSelect"
							value={noteValue}
							onChange={(e) => setNoteValue(e.target.value)}
						></Input>
						<Button onClick={addStaffNote} color="primary" type="submit" className="mt-1 btn-block">
							Add Note
						</Button>
					</Card>
				</Col>
			</Row>
		</>
	);
}
