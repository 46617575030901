import moment from 'moment';
import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
	Badge,
	Button,
	ButtonDropdown,
	Card,
	CardBody,
	CardHeader,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import { AskConfirm } from 'src/components/AskComponents';

import { useQuery } from '@tanstack/react-query';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import styled from 'styled-components';
import PlanModal from './PlanModal';
import { DatePicker } from 'reactstrap-date-picker';
import StudentPrint from './StudentPrint';

const StudentCancelled = styled.b`
	position: absolute;
	font-size: 2em;
	color: darkred;
	top: 25%;
	left: 60%;
	transform: rotate(-16deg);
	background: rgba(255, 255, 255, 0.7);
	border: 2px double darkred;
	border-radius: 12px;
	padding: 0px 10px;
	@media (max-width: 520px) {
		left: 35%;
	}
`;
export default function Today() {
	const navigate = useNavigate();
	let { date } = useParams();

	let theDate = () => {
		switch (date) {
			case 'today':
				return moment().format('YYYY-MM-DD');

			case 'tomorrow':
				return moment().add(1, 'day').format('YYYY-MM-DD');

			default:
				return moment(date).format('YYYY-MM-DD');
		}
	};

	const [selectedDate, setSelectedDate] = useState(theDate);

	const updateSelectedDate = (date) => {
		setSelectedDate(date);
		if (date === moment().format('YYYY-MM-DD')) return navigate(`/admin/schedule/today`);
		else if (date === moment().add(1, 'day').format('YYYY-MM-DD')) return navigate(`/admin/schedule/tomorrow`);
		return navigate(`/admin/schedule/${date}`);
	};

	const [activeTab, setActiveTab] = useState('Students');

	const { data, isLoading, error, refetch } = useQuery(
		['schedule', selectedDate],
		async () => {
			const { participation: participations } = await graphql(
				{
					query: `
						query ($date:date!){
							participation(where:{date:{_eq:$date}}) {
								id
								startTime
								startCheckAm
								status {
									id
									name
								}
								person {
									id
									name
									mobile
									dob
									weight
									participations(where:{date:{_lt:$date}, statusId:{_eq:1}}) {
										activity {
											name
											shorthand
										}
									}
								}
								activity {
									name
								}
								pilotUsedRebook
								activityIndex
								pendingDays
								customMessage
							}
						}`,
					variables: {
						date: selectedDate
					}
				},
				true
			);

			return { participations };
		},
		{ cacheTime: 0 }
	);

	const dateHasPassed = moment(selectedDate).isBefore(moment(), 'day');

	const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
	const [reviewData, setReviewData] = useState(null);
	const [weatherData, setWeatherData] = useState([]);
	const [staffData, setStaffData] = useState([]);

	const notifyPilots = async () => {
		try {
			await graphql({
				query: `
				mutation ($date: String!) {
					notifyPilots(date: $date)
				}
			`,
				variables: {
					date: selectedDate
				}
			});
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	if (isLoading) return <Spinner />;
	const activityGroups = data.participations.reduce((groups, student) => {
		const activityName = student.activity.name;
		if (!groups[activityName]) {
			groups[activityName] = [];
		}
		groups[activityName].push(student);
		return groups;
	}, {});
	return (
		<>
			<h3>
				{moment(selectedDate).format('ll')}
				<div style={{ width: '150px' }}>
					<DatePicker
						className="float-right"
						onChange={(e) => {
							updateSelectedDate(e.split('T')[0]);
						}}
						value={selectedDate}
					/>
				</div>
				<StudentPrint groups={activityGroups} date={selectedDate} />
				{/* <Input
					className="float-right"
					style={{ width: 150 }}
					value={selectedDate}
					type="date"
					onChange={(e) => {
						setSelectedDate(e.target.value);
					}}
				/> */}
			</h3>

			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Nav tabs>
						<NavItem>
							<NavLink className={activeTab == 'Students' ? 'active' : ''} onClick={() => setActiveTab('Students')}>
								<i className="fa fa-users" /> Students
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={activeTab == 'Weather' ? 'active' : ''} onClick={() => setActiveTab('Weather')}>
								<i className="fa fa-cloud" /> Weather
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={activeTab == 'Staff' ? 'active' : ''} onClick={() => setActiveTab('Staff')}>
								<i className="fa fa-user-shield" /> Staff
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="Students">
							<Row>
								<Col sm="12">
									<Row style={{ marginTop: '1rem' }}>
										<Col sm="12">
											{dateHasPassed ? (
												<Button
													color="warning"
													size="sm"
													onClick={() => {
														setIsReviewModalOpen(true);
														setReviewData({ type: 'all' });
													}}
												>
													<i className="fa fa-magnifying-glass" /> Review for everyone
												</Button>
											) : (
												<PlanModal onSubmit={refetch} participations={data.participations} selectedDate={selectedDate}>
													<Button color="warning" size="sm">
														<i className="fa fa-user-clock" /> Set for everyone
													</Button>
												</PlanModal>
											)}
											<AskConfirm
												title="Are you sure you want to notify pilots?"
												description={'Notify for ' + moment(selectedDate).format('ll')}
												onSubmit={() => notifyPilots()}
											>
												<Button color="danger" size="sm">
													<i className="fa fa-comment-dots" /> Finish & Notify Pilots
												</Button>
											</AskConfirm>
										</Col>
									</Row>

									{Object.entries(activityGroups).length !== 0 ? (
										Object.entries(activityGroups).map(([activityName, students]) => {
											return (
												<div key={activityName}>
													<hr style={{ height: '3px' }} />
													<h3>{activityName}</h3>
													<Row style={{ marginTop: '1rem' }}>
														{students.map((student, index) => {
															return (
																<CustomerCard
																	data={student}
																	key={index}
																	dateHasPassed={dateHasPassed}
																	refresh={refetch}
																	isLoading={isLoading}
																	selectedDate={selectedDate}
																	onReview={() => {
																		setIsReviewModalOpen(true);
																		setReviewData(student);
																	}}
																/>
															);
														})}
													</Row>
												</div>
											);
										})
									) : (
										<small>No results</small>
									)}
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="Weather">
							<Row className="mt-2">
								{weatherData?.hourly?.map((data, index) => {
									let time = moment.unix(data.dt);
									if (time.hour() < 6 || time.hour() > 22) return null;
									if (!time.isSame(selectedDate, 'day')) return null;

									return <WeatherCard data={data} />;
								})}
							</Row>
						</TabPane>
						<TabPane tabId="Staff">
							<Row>
								<Col sm="12">
									<Row style={{ marginTop: '1rem' }}>
										{staffData.length != 0 ? (
											staffData.map((student, index) => {
												return <StaffCard data={student} key={index} />;
											})
										) : (
											<small>No results</small>
										)}
									</Row>
								</Col>
							</Row>
						</TabPane>
					</TabContent>

					<ReviewModal
						isOpen={isReviewModalOpen}
						toggle={() => {
							setIsReviewModalOpen(!isReviewModalOpen);
						}}
						data={reviewData}
					/>
				</>
			)}
		</>
	);
}

const ReviewModal = ({ isOpen, toggle, data }) => {
	if (!data) return null;
	const { id, status, person, activity, activityIndex, pendingDays } = data;

	if (data.type == 'all')
		return (
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader>
					<i className="fas fa-dove" />
					Review of all!
				</ModalHeader>
				<ModalBody>
					<Input type="select">
						<option>Yes, student was present</option>
						<option>no, student was absent</option>
					</Input>
					<div className="mb-2" />
					Did the student qualify?
					<Input type="select">
						<option>no, more days neccesary</option>
						<option>Yes, they qualified today</option>
					</Input>
					<div className="mb-2" />
					Any notes for next time?
					<Input type="textarea" placeholder="notes..." />
				</ModalBody>
				<ModalFooter>
					<Button>Cancel</Button>
					<Button color="primary">Save</Button>
				</ModalFooter>
			</Modal>
		);

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader>
				<i className="fas fa-dove" />
				Review of {person.name}
			</ModalHeader>
			<ModalBody>
				{data && (
					<>
						<div className="mb-3 text-center">
							<h5>{person.name}</h5>
							<h6>
								{activity.name} (day {activityIndex})<br />
								<small>{pendingDays} day(s) pending</small>
							</h6>
							<h6>{person.weight} KG</h6>
							<h6>{moment().diff(moment(person.dob), 'years')} years old</h6>
							<a href={`tel:${person.mobile}`}>
								<i className="fa fa-phone-alt"></i> {person.mobile}
							</a>
						</div>
					</>
				)}
				<hr />
				Did the student attend today?
				<Input type="select">
					<option>Yes, student was present</option>
					<option>no, student was absent</option>
				</Input>
				<div className="mb-2" />
				Did the student qualify?
				<Input type="select">
					<option>no, more days neccesary</option>
					<option>Yes, they qualified today</option>
				</Input>
				<div className="mb-2" />
				Any notes for next time?
				<Input type="textarea" placeholder="notes..." />
			</ModalBody>
			<ModalFooter>
				<Button onClick={toggle}>Cancel</Button>
				<Button color="primary">Save</Button>
			</ModalFooter>
		</Modal>
	);
};

const CustomerCard = ({ data, onReview, dateHasPassed, refresh, isLoading, selectedDate }) => {
	const { id, status, person, activity, activityIndex, pilotUsedRebook, startCheckAm, startTime } = data;

	const [isOpen, setIsOpen] = useState(false);
	const [userDropdown, setUserDropdown] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<Col lg={6}>
			<Card>
				<CardHeader>
					<ButtonDropdown
						size="sm"
						isOpen={userDropdown}
						toggle={() => {
							setUserDropdown(!userDropdown);
						}}
					>
						<DropdownToggle caret>
							<i className="fa fa-user" />
							{person.name}
						</DropdownToggle>
						<DropdownMenu>
							<Link to={`/admin/pilot/${person.id}`}>
								<DropdownItem>
									<i className="fa fa-user" /> View profile
								</DropdownItem>
							</Link>
							{/* <DropdownItem divider />
							<DropdownItem>
								<i className="fa fa-sign-out-alt" /> Pilot Absent
							</DropdownItem> */}
						</DropdownMenu>
					</ButtonDropdown>
					{dateHasPassed ? (
						<Button color="warning" size="sm" onClick={onReview}>
							<i className="fa fa-magnifying-glass" /> Review
						</Button>
					) : (
						!isLoading && (
							<PlanModal onSubmit={refresh} participation={data} selectedDate={selectedDate}>
								<Button color="warning" size="sm">
									<i className="fa fa-user-clock" /> Set start
								</Button>
							</PlanModal>
						)
					)}
					{activityIndex === 1 && (
						<Badge color="success" style={{ float: 'right' }}>
							<i className="fa-solid fa-star-of-life" />
							&nbsp; First Day
						</Badge>
					)}
				</CardHeader>
				<CardBody>
					{startCheckAm && (
						<div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>
							<i className="fa fa-fw fa-refresh" /> Status: Please check in the morning
						</div>
					)}
					{!!startTime && (
						<div className="mt-2" style={{ color: 'green', fontWeight: 'bold' }}>
							<i className="fa fa-fw fa-clock" /> Status: Start at&nbsp;
							{moment(0, 'X').utc().startOf('day').add(startTime, 'seconds').format('HH:mm')}
						</div>
					)}
					<div>
						<i className="fa fa-fw fa-weight" />
						&nbsp;{person.weight} KG&nbsp;&nbsp;
						<i className="fa fa-fw fa-calendar" />
						&nbsp;
						{moment().diff(moment(person.dob), 'years')} years old
					</div>
					<b>
						<i className="fa fa-dove"></i> {activity.name} - Day {activityIndex}
					</b>
					<br />
					<b>
						Experience:{' '}
						{Object.entries(
							person.participations.reduce((acc, p) => {
								acc[p.activity.shorthand] = (acc[p.activity.shorthand] || 0) + 1;
								return acc;
							}, {})
						).map(([activity, count], index) => (
							<span key={'activity' + index}>
								<Badge pill color="success">
									{activity} x{count}
								</Badge>
								&nbsp;
							</span>
						))}
					</b>
					<br />
					<a href={`tel:${person.mobile}`}>
						<i className="fa fa-phone-alt"></i> {person.mobile}
					</a>
					<br />
					{pilotUsedRebook && (
						<div className="mt-2" style={{ color: 'green', fontWeight: 'bold' }}>
							<i className="fa fa-check" /> Additional day has been credited
						</div>
					)}

					{status.id == 2 && !pilotUsedRebook && (
						<div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>
							<i className="fa fa-times" /> Additional day has NOT been credited yet
						</div>
					)}

					{data.customMessage && (
						<div style={{ marginTop: '10px' }}>
							<i className="fa fa-fw fa-comment" />
							&nbsp;Custom Message:
							<br />
							<div style={{ whiteSpace: 'pre-wrap', overflowX: 'auto', marginLeft: '30px' }}>
								Hi {person.name.split(' ')[0]},<br />
								{data.customMessage}
							</div>
						</div>
					)}

					{status.id > 1 && <StudentCancelled>{cancelledMessage(status.id)}</StudentCancelled>}
				</CardBody>
			</Card>
		</Col>
	);
};

const StaffCard = ({ data }) => {
	const { pilot_id, person_first_name, person_last_name, person_mobile, availability_confirmed, availability_note } =
		data;

	return (
		<Col lg={6}>
			<Card>
				<CardHeader>
					<Link to={`/pilot/${pilot_id}`}>
						<i className="fa fa-user-tie" />
						{` ${person_first_name} ${person_last_name}`}
					</Link>
				</CardHeader>
				<CardBody>
					{availability_note ? <div>{availability_note}</div> : null}
					{!availability_confirmed ? <b style={{ color: 'red' }}>UNCONFIRMED</b> : null}
					<br />
					<a href={`tel:${person_mobile}`}>
						<i className="fa fa-phone-alt"></i> {person_mobile}
					</a>
				</CardBody>
			</Card>
		</Col>
	);
};

const WeatherCard = ({ data }) => {
	let m = moment.unix(data.dt);
	return (
		<Col lg={6}>
			<Card className="text-white bg-dark">
				<CardHeader>
					<b style={{ fontSize: 24 }}>{m.format('HH:mm')}</b>
					<span className="float-right">
						<b>{data.weather[0].main}</b> ({data.weather[0].description})
					</span>
				</CardHeader>
				<CardBody>
					<span className="float-right">
						<i className="fa fa-arrow-down" style={{ transform: `rotate(${data.wind_deg}deg)`, fontSize: '4em' }} />
						<br />
						<b>{Math.round(data.wind_speed * 2.237)} mph</b>
					</span>
					<i className="fa fa-cloud" style={{ fontSize: '1.3em' }}>
						{' '}
						{data.clouds}%
					</i>
				</CardBody>
			</Card>
		</Col>
	);
};

const cancelledMessage = (statusId) => {
	if (statusId == 2) return 'CANCELLED (By School)';
	if (statusId == 3) return 'PILOT ABSENT';
	if (statusId == 4) return 'CANCELLED BY PILOT';
	return '';
};
