import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function WorkshopPreviewModal({ open, setOpen, data }) {
	const toggle = () => setOpen(!open);

	if (!data) return null;
	return (
		<div>
			<Modal isOpen={open} toggle={toggle}>
				<ModalHeader toggle={toggle}>Workshop Booking</ModalHeader>
				<ModalBody>
					<p>
						<strong>Customer:</strong> {data.person.name}
					</p>
					<p>
						<strong>Service:</strong> {data.workshopBookingService?.product.name || 'No Service'}
					</p>
					<p>
						<strong>Status:</strong> {data.status.name}
					</p>
					<p>
						<strong>Equipment:</strong> {data.equipmentMake} {data.equipmentModel}
					</p>
					<p>
						<strong>Reason For Service:</strong> {data.otherInfo || 'No Reason Provided'}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
