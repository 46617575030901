import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Alert, Card } from 'reactstrap';
import graphql from 'src/utils/graphql';
import { AskFile } from '../AskComponents';
import Spinner from '../Spinner';
import LoadingButton from '../LoadingButton';

function EditProduct({ id, children, onSubmit }) {
	const [modal, setModal] = useState(false);
	const toggle = () => {
		if (saving) return;
		if (!modal) fetch();
		setModal(!modal);
	};

	const [values, setValues] = useState(null);
	const [saving, setSaving] = useState(false);

	const handleChange = (e) => {
		const { id, value } = e.target;

		if (id === 'visible' || id === 'giftable') {
			return setValues({ ...values, [id]: value === 'true' });
		}

		setValues({ ...values, [id]: value });
	};

	const handleSave = async () => {
		// console.log(values);
		await update({
			...values,
			price: parseFloat(values.price)
		});
		toggle();
	};

	const [product, setProduct] = useState(null);
	const fetch = useCallback(async () => {
		const { productByPk } = await graphql(
			{
				query: `query ($id: bigint!) {
					productByPk(id: $id) {
						id
						name
						description
						price
						giftable
						visible
						imageObject {
							url
						}
					}
				}
			`,
				variables: {
					id
				}
			},
			true
		);
		setValues({ ...productByPk, imageObject: undefined });
		setProduct(productByPk);
	}, [id]);

	const update = async (values) => {
		try {
			setSaving(true);
			await graphql({
				query: `
					mutation ($input: ProductInput!) {
						saveProduct(input: $input)
					}
				`,
				variables: {
					input: {
						id,
						...values
					}
				}
			});
			if (onSubmit) await onSubmit();
			await fetch();
		} catch (error) {
			alert(error.message);
		} finally {
			setSaving(false);
		}
	};

	return (
		<div>
			<span onClick={toggle}>
				{children || (
					<Button color="primary" outline>
						{' '}
						<i className="fa fa-pencil" />
						&nbsp;Edit Product
					</Button>
				)}
			</span>
			<Modal isOpen={modal} toggle={toggle}>
				{product ? (
					<>
						<ModalHeader toggle={toggle}>Edit Product</ModalHeader>
						<ModalBody>
							<FormGroup floating>
								<Input
									className="mb-2"
									type="text"
									id="name"
									placeholder="Enter name"
									value={values.name}
									onChange={handleChange}
								/>

								<Label for="name">Name</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									className="mb-2"
									type="textarea"
									id="description"
									placeholder="Enter description"
									value={values.description}
									onChange={handleChange}
								/>

								<Label for="description">Description</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									className="mb-2"
									type="number"
									id="price"
									placeholder="Enter price"
									value={values.price}
									onChange={handleChange}
								/>
								<Label for="price">Price</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									className="mb-2"
									type="select"
									id="giftable"
									placeholder="Enter giftable"
									value={values.giftable}
									onChange={handleChange}
								>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</Input>
								<Label for="giftable">Giftable</Label>
							</FormGroup>

							<FormGroup floating>
								<Input className="mb-2" type="select" id="visible" value={values.visible} onChange={handleChange}>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</Input>
								<Label for="giftable">Visible</Label>
							</FormGroup>

							<AskFile title="Upload Image" onSubmit={(file) => update({ imageObjectId: file.id })}>
								<Button color="primary" outline style={{ width: '100%' }}>
									Upload Image
								</Button>
							</AskFile>

							{product.imageObject && (
								<Card style={{ flexDirection: 'row-reverse', justifyContent: 'center' }}>
									<img top width="50%" src={product.imageObject.url} alt="Card image cap" />
								</Card>
							)}

							<LoadingButton
								className="mt-3"
								loading={saving}
								color="primary"
								style={{ width: '100%' }}
								onClick={handleSave}
							>
								Save
							</LoadingButton>
						</ModalBody>
					</>
				) : (
					<ModalBody>
						<Spinner noTransform />
					</ModalBody>
				)}
			</Modal>
		</div>
	);
}

export default EditProduct;
