import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	FormGroup,
	Input,
	InputGroup,
	Label,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	Row
} from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import Spinner from 'src/components/Spinner';
import { CartContext } from 'src/contexts/CartContext';
import graphql from 'src/utils/graphql';

export default function Cart(props) {
	const { productId, gift = false } = useParams();
	const [autoFillProduct, setAutoFillProduct] = useState(Number(productId));
	const navigate = useNavigate();
	const { user } = useApp();
	const [purchaseSuccess, setPurchaseSuccess] = useState(false);
	const [serverCart, setServerCart] = useState(null);
	const [loadingOverlay, setLoadingOverlay] = useState(false);
	const [accountNeeded, setAccountNeeded] = useState(false);
	const cart = useContext(CartContext);
	// const cart = useCart();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [confirmEmail, setConfirmEmail] = useState('');

	const [createAccount, setCreateAccount] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [userError, setUserError] = useState(null);

	// useEffect(() => {
	// 	if (email.toLowerCase() === confirmEmail.toLowerCase()) {
	// 	}
	// }, [email, confirmEmail]);

	let codes = [];
	if (user) codes = user.personDiscountCodes.map(({ discountCode }) => discountCode.id);
	if (cart.codes.length) codes = [...codes, ...cart.codes];

	const createPaypalOrder = async () => {
		try {
			setLoadingOverlay(true);
			// Validate inputs
			if (!user) {
				if (!firstName || !lastName || !email || !confirmEmail)
					return setUserError('Please fill out all required fields');

				if (email.toLowerCase() !== confirmEmail.toLowerCase()) return setUserError('Emails do not match');
				if (createAccount || accountNeeded) {
					if (!password || !confirmPassword) return setUserError('Please fill out both password fields');
					if (password !== confirmPassword) return setUserError('Passwords do not match');
				}
			}

			const resolvedPath = window.websiteEmbed
				? window.location.origin + window.location.pathname + '#'
				: window.location.origin;

			console.log('resolvedPath', resolvedPath);

			const { createOrder } = await graphql({
				query: `
				mutation($items: String!, $codes: String!, $cancelURL: String!, $successURL: String!, $guest: PersonInput,$headless:Boolean!) {
					createOrder(items: $items, codes:$codes, cancelURL: $cancelURL, successURL: $successURL,guest: $guest,headless:$headless)
				}
			`,
				variables: {
					items: JSON.stringify(cart.items),
					codes: JSON.stringify(codes),
					guest: user
						? null
						: {
								firstName,
								lastName,
								email,
								password: password ? password : null
						  },
					cancelURL: resolvedPath + '/cart',
					successURL: resolvedPath + '/cart/thankyou?email=' + encodeURIComponent(user?.email || email),
					headless: window.websiteEmbed ? true : false
				}
			});

			// Redirect to payment page
			if (createOrder) window.top.location = createOrder;
			else throw new Error('No redirect URL returned');
		} catch (error) {
			alert("Sorry, we couldn't create your order");
			console.log(error);
		} finally {
			setLoadingOverlay(false);
		}
	};

	const fetchProducts = useCallback(async () => {
		try {
			setLoadingOverlay(true);

			const { hydrateCartProducts } = await graphql(
				{
					query: `
					query($cartString: String!, $codes: String!) {
						hydrateCartProducts(args: {cart_products: $cartString, codes: $codes}) {
							unitPrice
							price
							normalPrice
							quantity
							gift
							product {
								id
								name
								description
								giftable
								imageObject {
									url
								}
							}
						}
					}
				`,
					variables: {
						cartString: JSON.stringify(
							cart.items.map((item) => ({ product_id: item.id, gift: item.gift, quantity: item.quantity }))
						),
						codes: JSON.stringify(codes)
					}
				},
				true
			);
			console.log('length', user?.personDiscountCodes.length);
			let needAccount = false;
			for (const item of hydrateCartProducts) {
				if (!item.gift) needAccount = true;
			}
			setAccountNeeded(needAccount);
			setServerCart(hydrateCartProducts);
		} catch (error) {
			console.error(error);
			alert("Sorry, we couldn't fetch your cart");
		} finally {
			setLoadingOverlay(false);
		}
	}, [cart, user]);

	const [codeInput, setCodeInput] = useState('');
	const addCode = () => {
		if (cart.codes.includes(codeInput)) return setCodeInput('');
		if (codeInput.length) cart.addCode(codeInput);
		setCodeInput('');
	};

	useEffect(() => {
		if (!autoFillProduct) return fetchProducts();
		cart.addItem(autoFillProduct, gift === 'gift');
		setAutoFillProduct(null);
		navigate('/cart');
	}, [autoFillProduct, cart, navigate, fetchProducts]);

	if (!serverCart) return <Spinner />;
	return (
		<Container>
			{/* loading overlay */}
			{loadingOverlay && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255,255,255,0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 1000
					}}
				>
					<Spinner />
				</div>
			)}
			<Row className="mt-4">
				<Col md={8}>
					<Card style={{ minHeight: '60vh', padding: '10px' }}>
						<h3>Shopping Cart ({serverCart.length} Items)</h3>
						<hr />
						{serverCart.length > 0 ? (
							serverCart.map(({ product, quantity, gift, price, normalPrice, unitPrice }, index) => (
								<div key={index}>
									<Row>
										<Col md={3} className="m-auto">
											{product.imageObject && (
												<img
													src={product.imageObject.url}
													alt={product.name}
													className="d-block mx-auto img-fluid w-100"
												/>
											)}
										</Col>
										<Col md={9}>
											<Row>
												<Col md={12}>
													<h5>
														{product.name} {gift && <>(Gift)</>}
													</h5>
													<p>{product.description}</p>
												</Col>
												<Col md={4}>
													{/* format currency */}
													{formatCurrency(price)}&nbsp;
													{normalPrice !== price && (
														<small style={{ color: '#ff6666' }}>
															&nbsp;
															<del>{formatCurrency(normalPrice)}</del>
														</small>
													)}
												</Col>
												<Col md={4}>
													{product.giftable && (
														<>
															<Input
																type="switch"
																id={`gift-${product.id}`}
																name={`gift-${product.id}`}
																label="Gift"
																checked={gift}
																onChange={() => cart.toggleGift(product.id, gift)}
															/>
															&nbsp;
															<Label for={`gift-${product.id}`}>Gift Item</Label>
														</>
													)}
												</Col>
												<Col md={4}>
													<i
														style={{ cursor: 'pointer' }}
														className="fa fa-trash"
														onClick={() => cart.removeItem(product.id, gift)}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									{/* hr if more rows */}
									{index < serverCart.length - 1 && <hr />}
								</div>
							))
						) : (
							<>
								<span>No products found</span>
							</>
						)}
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<h3>Summary</h3>
							<hr />
							<p>
								<b>Discount:</b>&nbsp;
								{/* take price off from normal price and work out total */}
								{formatCurrency(
									serverCart.reduce((prevVal, currVale) => prevVal + currVale.normalPrice - currVale.price, 0) || 0
								)}
							</p>
							<p>
								<b>Total:</b>&nbsp;
								{formatCurrency(serverCart.reduce((prevVal, currVale) => prevVal + currVale.price, 0) || 0)}
							</p>
							<hr className="mt-2 bt-2" />
							<InputGroup>
								<Input value={codeInput} onChange={(e) => setCodeInput(e.target.value)} placeholder="Promo code" />
								<Button onClick={() => addCode()}>
									<i className="fa fa-tag" /> Apply
								</Button>
							</InputGroup>
							{(cart.codes.length > 0 || user?.personDiscountCodes.length > 0) && (
								<h6 style={{ marginLeft: '5px', marginTop: '15px' }}>Available Discounts</h6>
							)}
							<ListGroup style={{ marginTop: '5px' }} addonType="append">
								{cart.codes.map((code) => (
									<ListGroupItem>
										{code}
										<i
											style={{ float: 'right', cursor: 'pointer' }}
											className="fa fa-times"
											onClick={() => cart.removeCode(code)}
										/>
									</ListGroupItem>
								))}
								{user?.personDiscountCodes.map(({ discountCode }) => (
									<ListGroupItem>{discountCode.name || discountCode.id}</ListGroupItem>
								))}
							</ListGroup>
							{!user && (
								<>
									<hr />
									<div className="row">
										<div className="col-md-6">
											<FormGroup floating>
												<Input
													id="firstName"
													type="text"
													value={firstName}
													onChange={(e) => setFirstName(e.target.value)}
													placeholder="a"
												/>
												<Label for="firstName">First Name</Label>
											</FormGroup>
										</div>
										<div className="col-md-6">
											<FormGroup floating>
												<Input
													id="lastName"
													type="text"
													value={lastName}
													onChange={(e) => setLastName(e.target.value)}
													placeholder="a"
												/>
												<Label for="lastName">Last Name</Label>
											</FormGroup>
										</div>
									</div>
									<FormGroup floating>
										<Input
											id="email"
											type="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder="a"
										/>
										<Label for="email">Email</Label>
									</FormGroup>
									<FormGroup floating>
										<Input
											id="confirmEmail"
											type="email"
											value={confirmEmail}
											onChange={(e) => setConfirmEmail(e.target.value)}
											placeholder="a"
										/>
										<Label for="confirmEmail">Confirm Email</Label>
									</FormGroup>

									{!accountNeeded && (
										<FormGroup switch className="mb-2">
											<Input
												type="switch"
												defaultChecked={createAccount}
												onClick={() => {
													setCreateAccount(!createAccount);
												}}
											/>
											<Label check onClick={() => setCreateAccount(!createAccount)}>
												Create an account?
											</Label>
										</FormGroup>
									)}

									{(createAccount || accountNeeded) && (
										<>
											<FormGroup floating>
												<Input
													id="password"
													type="password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
													placeholder="a"
												/>
												<Label for="password">Password</Label>
											</FormGroup>
											<FormGroup floating>
												<Input
													id="confirmPassword"
													type="password"
													value={confirmPassword}
													onChange={(e) => setConfirmPassword(e.target.value)}
													placeholder="a"
												/>
												<Label for="confirmPassword">Confirm Password</Label>
											</FormGroup>
										</>
									)}

									{userError && <Alert color="warning">{userError}</Alert>}
								</>
							)}
							<Button
								disabled={serverCart.length === 0}
								className="mb-2 mt-4"
								color="success"
								onClick={() => createPaypalOrder()}
								outline
								block
							>
								<i className="fa fa-credit-card" /> Buy Now
							</Button>
							<Button color="success" onClick={() => navigate('/choose-sport')} outline block>
								<i className="fa fa-plus" /> Continue Shopping
							</Button>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

// function to format currency gbp
const formatCurrency = (amount) => {
	return amount.toLocaleString('en-GB', {
		style: 'currency',
		currency: 'GBP'
	});
};
