import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import LoadingButton from 'src/components/LoadingButton';

function AddWorkshopBookingModal({ personId, children, ...args }) {
	const queryClient = useQueryClient();
	const [modal, setModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);

	const [workshopServiceId, setWorkshopServiceId] = useState();
	const [date, setDate] = useState(null);
	const [statusId, setStatusId] = useState();

	const toggle = () => {
		if (modal) {
			setWorkshopServiceId();
			setDate(null);
			setStatusId();
			setError(null);
		}

		setModal(!modal);
	};

	const { data, isLoading } = useQuery(['AddWorkshopBookingModal'], async () => {
		const data = await graphql(
			{
				query: `#graphql
					{
						workshopService {
							serviceId
							serviceName
							product {
								name
							}
						}
						workshopBookingStatus {
							id
							name
						}
					}`
			},
			true
		);
		return data;
	});

	const save = async () => {
		try {
			setError();
			setSaving(true);

			await graphql(
				{
					query: `#graphql
					mutation($input: WorkshopBookingInsertInput!) {
						insertWorkshopBookingOne(object:$input) {
							id
						}
					}
					`,
					variables: {
						input: {
							personId,
							workshopServiceId,
							date,
							statusId
						}
					}
				},
				true
			);
			setSaving(false);
			toggle();
			queryClient.invalidateQueries(['person', personId]);
		} catch (error) {
			setSaving(false);
			console.log(error);
			setError(error.message);
		}
	};

	if (isLoading) return <Spinner />;
	return (
		<div>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Add Workshop Booking</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}

					<FormGroup floating>
						<Input
							onChange={(e) => setWorkshopServiceId(e.target.value)}
							value={workshopServiceId}
							id="service"
							placeholder="service"
							type="select"
						>
							<option value="">Select Service</option>
							{data.workshopService
								.filter((e) => e.product)
								.sort((a, b) => a.serviceName.localeCompare(b.serviceName))
								.map((e) => (
									<option key={e.serviceId} value={e.serviceId}>
										{e.product?.name}
									</option>
								))}
						</Input>
						<Label for="service">Service</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							onChange={(e) => setStatusId(e.target.value)}
							value={statusId}
							id="status"
							placeholder="status"
							type="select"
						>
							<option value="">Select Status</option>
							{data.workshopBookingStatus
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((e) => (
									<option key={e.id} value={e.id}>
										{e.name}
									</option>
								))}
						</Input>
						<Label for="service">Status</Label>
					</FormGroup>

					<FormGroup floating>
						<Input onChange={(e) => setDate(e.target.value)} value={date} id="date" placeholder="date" type="date" />
						<Label for="date">Drop Off Date</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<LoadingButton loading={saving} color="primary" onClick={save}>
						Add Workshop Booking
					</LoadingButton>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddWorkshopBookingModal;
