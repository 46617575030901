import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Alert } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';
import LoadingButton from '../LoadingButton';

export default function AskLogin({
	children,
	message = 'Please login or register to continue',
	buttonMessage = 'Login',
	...otherProps
}) {
	const { login, register } = useApp();
	// No state will ask the user Register or Login
	const [state, setState] = useState('');
	const [modal, setModal] = useState(false);
	const toggle = () => {
		if (!modal) handleGoBack();
		setModal(!modal);
	};

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmEmail, setConfirmEmail] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [resetSent, setResetSent] = useState(false);

	const handleLogin = async () => {
		try {
			setLoading(true);
			await login(email, password);
			setModal(false);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleRegister = async () => {
		try {
			if (!firstName || !lastName || !email || !password || !confirmEmail || !confirmPassword)
				return setError('Please fill in all fields');
			if (email !== confirmEmail) return setError('Emails do not match');
			if (password !== confirmPassword) return setError('Passwords do not match');
			setLoading(true);
			await register(firstName, lastName, email, password);
			setModal(false);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleResetPassword = async () => {
		try {
			setLoading(true);
			await graphql({
				query: `#graphql
					mutation($email: String!) {
						forgotPassword(email: $email)
					}`,
				variables: {
					email
				}
			});
			setResetSent(true);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleGoBack = () => {
		setResetSent(false);
		setState('');
		setError(null);

		setFirstName('');
		setLastName('');
		setEmail('');
		setPassword('');
		setConfirmEmail('');
		setConfirmPassword('');
	};

	return (
		<div style={{ display: 'inline' }}>
			{children ? (
				<span onClick={toggle}>{children}</span>
			) : (
				<Button outline color="primary" {...otherProps} onClick={toggle}>
					<i className="fa-solid fa-user" />
					&nbsp;{buttonMessage}
				</Button>
			)}
			<Modal centered isOpen={modal} toggle={toggle}>
				{!state && (
					<>
						<ModalHeader>{message}</ModalHeader>
						<ModalBody>
							<div style={{ display: 'flex', justifyContent: 'space-around' }}>
								<Button color="primary" onClick={() => setState('register')}>
									Register
								</Button>
								<Button color="primary" onClick={() => setState('login')}>
									Sign In
								</Button>
							</div>
						</ModalBody>
					</>
				)}
				{state === 'login' && (
					<>
						<ModalBody>
							<h4>
								<i onClick={handleGoBack} className="fa-solid fa-arrow-left"></i>&nbsp; Login
							</h4>
							<hr />
							{error && (
								<Alert style={{ padding: '7px' }} color="danger">
									{error}
								</Alert>
							)}
							<FormGroup floating>
								<Input
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									id="email"
									placeholder="Email"
									type="email"
								/>
								<Label for="email">Email</Label>
							</FormGroup>
							<FormGroup floating>
								<Input
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									id="password"
									placeholder="Password"
									type="password"
								/>
								<Label for="password">Password</Label>
							</FormGroup>
							{/* forgot password */}
							<a
								style={{ float: 'right' }}
								onClick={() => {
									setError(null);
									setState('reset-password');
								}}
								href="#"
							>
								Forgot Password or need to create one?
							</a>

							<LoadingButton
								loading={loading}
								style={{ width: '100%' }}
								className="mt-3"
								color="primary"
								onClick={handleLogin}
							>
								Login
							</LoadingButton>
						</ModalBody>
					</>
				)}
				{state === 'reset-password' && (
					<>
						{' '}
						<ModalBody>
							<h4>
								<i onClick={handleGoBack} className="fa-solid fa-arrow-left"></i>&nbsp; Set New Password
							</h4>
							<hr />
							{error && (
								<Alert style={{ padding: '7px' }} color="danger">
									{error}
								</Alert>
							)}
							{resetSent && (
								<Alert style={{ padding: '7px' }} color="success">
									Reset link sent to {email}
								</Alert>
							)}
							<FormGroup floating>
								<Input
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									id="email"
									placeholder="Email"
									type="email"
								/>
								<Label for="email">Email</Label>
							</FormGroup>
							<LoadingButton
								loading={loading}
								style={{ width: '100%' }}
								className="mt-3"
								color="primary"
								onClick={handleResetPassword}
							>
								Send Link
							</LoadingButton>
						</ModalBody>
					</>
				)}
				{state === 'register' && (
					<>
						<ModalBody>
							<h4>
								<i onClick={handleGoBack} className="fa-solid fa-arrow-left"></i>&nbsp; Register
							</h4>
							<hr />
							{error && (
								<Alert style={{ padding: '7px' }} color="danger">
									{error}
								</Alert>
							)}
							<div className="row">
								<div className="col-md-6">
									<FormGroup floating>
										<Input
											onChange={(e) => setFirstName(e.target.value)}
											value={firstName}
											id="firstName"
											placeholder="name"
											type="text"
										/>
										<Label for="firstName">First Name</Label>
									</FormGroup>
								</div>
								<div className="col-md-6">
									<FormGroup floating>
										<Input
											onChange={(e) => setLastName(e.target.value)}
											value={lastName}
											id="lastName"
											placeholder="name"
											type="text"
										/>
										<Label for="lastName">Last Name</Label>
									</FormGroup>
								</div>
							</div>
							<FormGroup floating>
								<Input
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									id="email"
									placeholder="Email"
									type="email"
								/>
								<Label for="email">Email</Label>
							</FormGroup>
							<FormGroup floating>
								<Input
									onChange={(e) => setConfirmEmail(e.target.value)}
									value={confirmEmail}
									id="confirmEmail"
									placeholder="Email"
									type="email"
								/>
								<Label for="confirmEmail">Confirm Email</Label>
							</FormGroup>
							<FormGroup floating>
								<Input
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									id="password"
									placeholder="Password"
									type="password"
								/>
								<Label for="password">Password</Label>
							</FormGroup>
							<FormGroup floating>
								<Input
									onChange={(e) => setConfirmPassword(e.target.value)}
									value={confirmPassword}
									id="confirmPassword"
									placeholder="Password"
									type="password"
								/>
								<Label for="confirmPassword">Confirm Password</Label>
							</FormGroup>
							<LoadingButton
								loading={loading}
								style={{ width: '100%' }}
								className="mt-3"
								color="primary"
								onClick={handleRegister}
							>
								Register
							</LoadingButton>
						</ModalBody>
					</>
				)}
			</Modal>
		</div>
	);
}
