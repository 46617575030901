import React, { useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Button, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import ActivityModal from './ActivityModal';
import useMediaQuery from 'src/utils/useMediaQuery';
import WorkshopPreviewModal from './WorkshopPreviewModal';

export default function Calendar() {
	const isMobile = useMediaQuery('(max-width: 599px)');
	const [activityState, setActivityState] = useState();
	const [bookingState, setBookingState] = useState();

	const [dates, setDates] = useState({
		start: undefined,
		end: undefined
	});

	const { data, error, isLoading } = useQuery(
		['calendar', dates.start, dates.end],
		async () => {
			const { workshopAvailability, workshopBooking } = await graphql(
				{
					query: `#graphql
					query ($start: date!, $end: date!) {
						workshopAvailability(where: {availabilityDate: {_gte: $start, _lte: $end}}) {
							availabilityDate
							availabilityEndDate
							availableSpaces
						}
						workshopBooking (where: {date: {_gte: $start, _lte: $end}}) {
							date
							person {
								name
							}
							status {
								id
								name
							}
							workshopBookingService {
								product {
									name
								}
							}
							equipmentMake
							equipmentModel
							otherInfo
						}		
					}
					`,
					variables: {
						...dates
					}
				},
				true
			);
			return { workshopAvailability, workshopBooking };
		},
		{ enabled: Boolean(dates.start) }
	);
	const events = {
		workshopBookings: data?.workshopBooking.map((e) => {
			return {
				title: `${e.person.name} - ${e.status.name}`,
				start: moment(e.date).format('YYYY-MM-DD'),
				end: moment(e.date).add(1, 'days').format('YYYY-MM-DD'),
				backgroundColor: e.status.id === 1 || e.status.id === 2 ? '#949699' : '#0d6efd',
				borderColor: e.status.id === 1 || e.status.id === 2 ? '#949699' : '#0d6efd',
				type: 'activityBooking',
				data: {
					...e
				}
			};
		}),
		workshopAvailability: data?.workshopAvailability.map((e) => {
			const backgroundColor = e.availableSpaces === 0 ? '#FF0000' : '#198754';
			const borderColor = e.availableSpaces === 0 ? '#FF0000' : '#198754';

			return {
				title:
					e.availableSpaces === 0 ? 'no workshop spaces available' : `${e.availableSpaces}x workshop spaces available`,
				start: moment(e.availabilityDate).format('YYYY-MM-DD'),
				end: moment(e.availabilityEndDate).add(1, 'days').format('YYYY-MM-DD'),
				// blue{
				backgroundColor,
				borderColor,
				type: 'activityAvailability',
				data: {
					slots: e.availableSpaces,
					date: e.availailityDate,
					date: e.availabilityEndDate
				}
			};
		})
	};

	return (
		<>
			<Alert
				style={{
					backgroundColor: '#272a2f',
					borderColor: '#747c8b',
					color: 'white',
					textAlign: 'center'
				}}
				className="my-2"
				color="info"
			>
				<h5>Attention</h5>
				If there are spaces availble for a workshop, please add the date via the 'Edit Availability' button and these
				will be shown on the calendar with a green bar. If there are no spaces, these will be shown by a red bar.
			</Alert>
			<div className="mb-3">
				<Button
					color="primary"
					onClick={() => {
						setActivityState({
							slots: 0,
							date: ''
						});
					}}
				>
					<i className="fa-solid fa-circle-plus" />
					&nbsp;Edit Availability
				</Button>
				&nbsp;
			</div>
			<FullCalendar
				plugins={[dayGridPlugin, listPlugin]}
				initialView={isMobile ? 'listMonth' : 'dayGridMonth'}
				events={events.workshopAvailability && [...events.workshopAvailability, ...events.workshopBookings]}
				height={'auto'}
				eventClick={(e) => {
					e.event.extendedProps.type === 'activityBooking' && setBookingState(e.event.extendedProps.data);
					e.event.extendedProps.type === 'closure' && setClosureState(e.event.extendedProps.data);
					e.event.extendedProps.type === 'activityAvailability' && setActivityState(e.event.extendedProps.data);
				}}
				datesSet={(e) => {
					setDates({
						start: moment(e.start).format('YYYY-MM-DD'),
						end: moment(e.end).format('YYYY-MM-DD')
					});
				}}
			/>

			<ActivityModal state={activityState} setState={setActivityState} data={data} />
			<WorkshopPreviewModal open={bookingState} setOpen={setBookingState} data={bookingState} />
		</>
	);
}
