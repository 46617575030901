import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';

import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import DiscountDialog from './DiscountDialog';
import { AskConfirm } from 'src/components/AskComponents';
import ManageDiscountsDialog from './ManageDiscountsDialog';

export default function Discounts() {
	const date = moment().format('YYYY-MM-DD');
	const { data, isLoading, error, refetch } = useQuery(['discounts'], async () => {
		const { discountCode } = await graphql(
			{
				query: `
				query ($date: date) {
					discountCode(where: {expiry: {_gte: $date}}) {
						id
						name
						singleUse
						expiry
						note
						createdPerson {
							name
						}
					}
				}

			`,
				variables: { date }
			},
			true
		);
		return { discountCode };
	});

	const deleteDiscount = async (id) => {
		await graphql(
			{
				query: `
				mutation ($id:String!){
					deleteDiscountCodeByPk(id:$id) {
						id
					}
				}
			`,
				variables: { id }
			},
			true
		);
		await refetch();
	};

	if (isLoading) return <Spinner />;
	return (
		<div>
			<DiscountDialog
				style={{
					float: 'right',
					marginBottom: '10px'
				}}
			/>
			<Table hover className="mt-2">
				<thead>
					<tr>
						<td>Discount Code</td>
						<td>Discount Name</td>
						<td>Single Use</td>
						<td>Expiry</td>
						<td>Notes</td>
						<td>Created By</td>
						<td></td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.discountCode &&
						data.discountCode.map((d, i) => {
							return (
								<tr key={i}>
									<td>{d.id}</td>
									<td> {d.name}</td>
									<td>{d.singleUse ? 'Yes' : 'No'}</td>
									<td>{moment(d.expiry).format('DD/MM/YYYY')}</td>
									<td>
										<i
											href="#"
											id={`tooptip${i}`}
											className={d.note ? 'fa-solid fa-note-sticky' : 'fa-regular fa-note-sticky'}
										/>
										{d.note && (
											<UncontrolledTooltip placement="right" target={`tooptip${i}`}>
												{/* note icon */}
												{d.note}
											</UncontrolledTooltip>
										)}
									</td>
									<td>{d.createdPerson?.name || 'Unknown'}</td>
									<td>
										{console.log(d)}
										<DiscountDialog discountCode={d}>
											<Button color="primary" size="sm">
												<i className="fa-solid fa-pen-to-square fa-fw" />
												Edit
											</Button>
										</DiscountDialog>
										&nbsp;
										<ManageDiscountsDialog discountCode={d.id}>
											<Button color="primary" size="sm">
												<i className="fa-solid fa-sterling-sign fa-fw" />
												Manage
											</Button>
										</ManageDiscountsDialog>
									</td>
									<td>
										<AskConfirm
											onSubmit={() => deleteDiscount(d.id)}
											title="Delete Discount Code"
											description="Are you sure you want to delete this discount code?"
										>
											<i className="fa fa-times" style={{ cursor: 'pointer' }} />
										</AskConfirm>
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</div>
	);
}
