import React, { useState, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Col, Row } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';
import LoadingButton from 'src/components/LoadingButton';

export default function ProfileSetupModal({ children, open, setOpen, ...args }) {
	const toggle = () => setOpen(!open);
	const [saving, setSaving] = useState(false);
	const { user, fetchUser } = useApp();

	const [state, setState] = useState({
		firstName: user.firstName,
		lastName: user.lastName,
		mobile: user.mobile,
		weight: user.weight,
		height: user.height,
		dob: user.dob,
		gender: user.gender,
		referralSource: user.referralSource
	});

	const isValidValue = (v, key) => {
		if (key === 'referralSource') return true;
		if (key === 'gender') {
			return v === true || v === false;
		}
		return !!v;
	};
	const stateComplete = Object.entries(state).every(([key, value]) => isValidValue(value, key));

	const updateProfile = useCallback(async () => {
		try {
			setSaving(true);
			await graphql({
				query: `mutation ($input: PersonInput!) {
				savePerson(input: $input)
			}`,
				variables: {
					input: {
						...state,
						id: user.id
					}
				}
			});
			await fetchUser();
		} catch (error) {
			console.error(error);
		} finally {
			setSaving(false);
		}
	}, [user, state]);

	const handleChange = (e) => {
		const newState = {
			...state,
			[e.target.name]: e.target.value
		};
		if (e.target.name === 'gender') newState.gender = e.target.value === 'true';
		setState(newState);
	};

	return (
		<>
			<span onClick={toggle}>{children}</span>
			<Modal isOpen={open} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Please complete your profile</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={6}>
							<FormGroup floating>
								<Input type="text" name="firstName" id="firstName" value={state.firstName} onChange={handleChange} />
								<Label for="firstName">First Name</Label>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup floating>
								<Input type="text" name="lastName" id="lastName" value={state.lastName} onChange={handleChange} />
								<Label for="lastName">Last Name</Label>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup floating>
						<Input type="text" name="mobile" id="mobile" value={state.mobile} onChange={handleChange} />
						<Label for="mobile">Mobile</Label>
					</FormGroup>
					<Row>
						<Col md={6}>
							<FormGroup floating>
								<Input type="text" name="weight" id="weight" value={state.weight} onChange={handleChange} />
								<Label for="weight">Weight (KG)</Label>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup floating>
								<Input type="text" name="height" id="height" value={state.height} onChange={handleChange} />
								<Label for="height">Height (CM)</Label>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup floating>
								<Input type="date" name="dob" id="dob" value={state.dob} onChange={handleChange} />
								<Label for="dob">Date of Birth</Label>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup floating>
								<Input type="select" name="gender" id="gender" value={state.gender} onChange={handleChange}>
									<option value={null}>Please Select</option>
									<option value={true}>Male</option>
									<option value={false}>Female</option>
								</Input>
								<Label for="gender">Gender</Label>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup floating>
						<Input
							type="text"
							name="referralSource"
							id="referralSource"
							value={state.referralSource}
							onChange={handleChange}
						/>
						<Label for="referralSource">How did you hear about Green Dragons?</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<LoadingButton loading={saving} disabled={!stateComplete} color="primary" onClick={updateProfile}>
						Complete Profile
					</LoadingButton>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
