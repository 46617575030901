import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import { Table, Nav, NavItem, NavLink, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import moment from 'moment';
import { AskSelect, AskText } from 'src/components/AskComponents';
import { useNavigate } from 'react-router-dom';

export default function Emails() {
	const [currentFolder, setCurrentFolder] = React.useState(1);
	const { data: folders, isLoading } = useQuery(
		['emailFolders'],
		async () => {
			const { emailFolder } = await graphql(
				{
					query: `
					{
						emailFolder {
							id
							name
						}
					}
					`
				},
				true
			);
			return emailFolder;
		},
		true
	);
	if (isLoading) return <Spinner />;

	return (
		<div>
			<Nav tabs>
				{folders.map((folder, index) => (
					<NavItem key={index}>
						<NavLink active={currentFolder === index + 1} onClick={() => setCurrentFolder(index + 1)} href="#">
							{folder.name}
						</NavLink>
					</NavItem>
				))}
			</Nav>

			<EmailFolder folders={folders} folder={currentFolder} />
		</div>
	);
}

function EmailFolder({ folder, folders }) {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { data, isLoading } = useQuery(['emails', folder], async () => {
		const data = await graphql(
			{
				query: `
					query ($folder:bigint!){
						email(where:{folderId:{_eq:$folder}}) {
							id
							name
							subject
							created
							sesEmails {
								id
								recipientsAggregate {
									aggregate {
										count
									}
								}
							}
						}
					}
			`,
				variables: {
					folder
				}
			},
			true
		);
		return data;
	});

	const saveEmail = async (values) => {
		await graphql({
			query: `
			mutation ($input: EmailInput!) {
				saveEmail(input: $input)
			}`,
			variables: { input: values }
		});
		await queryClient.invalidateQueries('emails', folder);
	};

	const copyEmail = async (templateId, name) => {
		const { copyEmail } = await graphql({
			query: `mutation($templateId: ID!, $name: String) {
				copyEmail(id: $templateId,name: $name)
			}`,
			variables: {
				templateId,
				name
			}
		});
		navigate(`/admin/unlayer/${copyEmail}`);
	};

	if (isLoading) return <Spinner />;
	return (
		<Table hover>
			<thead>
				<tr>
					<th>Id</th>
					<th>Name</th>
					<th>Subject</th>
					<th>Created / Sent</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{data.email
					.slice(0)
					.reverse()
					.map((email) => (
						<tr key={email.id}>
							<td>{email.id}</td>
							<td>
								<a href="" onClick={() => navigate(`/admin/unlayer/${email.id}`)}>
									{email.name || 'No Name'}
								</a>
							</td>
							<td>{email.subject}</td>
							<td>{moment(email.created).fromNow()}</td>
							<td>
								{email.sesEmails.length > 0 && <EmailDropdown emails={email.sesEmails} />}
								<AskSelect
									title="Move to folder"
									values={folders
										.filter((f) => f.id !== folder)
										.map((folder) => ({ value: folder.id, text: folder.name }))}
									onSubmit={(value) => saveEmail({ id: email.id, folderId: value })}
								>
									<Button size="sm" outline>
										<i className="fa-regular fa-folder" />
										&nbsp; Move to folder
									</Button>
								</AskSelect>
								&nbsp;
								<AskText
									onSubmit={(name) => copyEmail(email.id, name)}
									title="What should we name this draft?"
									description="This will create a copy of this email in the drafts folder"
								>
									<Button size="sm" outline color="primary">
										<i className="fa-solid fa-copy" />
										&nbsp; Create Draft
									</Button>
								</AskText>
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}

const EmailDropdown = ({ emails }) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	return (
		<Dropdown isOpen={open} toggle={toggle}>
			<DropdownToggle size="sm" outline caret>
				<i className="fa-regular fa-percent" />
				&nbsp; Reports
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem header>Sent Emails</DropdownItem>
				{emails.map((email) => (
					<DropdownItem onClick={(e) => navigate(`/admin/emails/${email.id}`)}>
						View Email ({email.recipientsAggregate.aggregate.count})
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};
