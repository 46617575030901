import { useCallback, useRef, useState } from 'react';

import EmailEditor from 'react-email-editor';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import {
	Input,
	FormGroup,
	Label,
	Button,
	DropdownToggle,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import PickerModal from '../Emailer/PickerModal';
import SendModal from './SendModal';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import swal from 'src/utils/swal';
import { useNavigate } from 'react-router-dom';

export default function Emailer() {
	const navigate = useNavigate();
	const { id } = useParams();
	const emailEditorRef = useRef(null);
	const queryClient = useQueryClient();
	const [to, setTo] = useState([]);
	const [subject, setSubject] = useState('');
	const [name, setName] = useState('');
	const [html, setHtml] = useState(null);
	const [text, setText] = useState(null);
	const [ready, setReady] = useState(false);
	const [saving, setSaving] = useState(false);
	const [designString, setDesignString] = useState(null);

	const { data, isLoading } = useQuery(['unlayer'], async () => {
		const data = await graphql(
			{
				query: `
				query ($id:Int!){
					emailByPk(id:$id) {
						id
						name
						subject
						unlayerDesign
					}
				}
			`,
				variables: {
					id
				}
			},
			true
		);
		return { email: data.emailByPk };
	});

	const saveTemplate = useCallback(
		async (values, refresh = true) => {
			const { saveEmail } = await graphql({
				query: `
					mutation($input: EmailInput!) {
						saveEmail(input: $input)
					}
				`,
				variables: {
					input: {
						...values
					}
				}
			});
			if (refresh) await queryClient.invalidateQueries('unlayer');
			return saveEmail;
		},
		[queryClient, id]
	);

	const generateHTML = useCallback(
		async (values) => {
			setHtml(null);
			setText(null);
			const { html, text } = await exportContent();
			setHtml(html);
			setText(text);
		},
		[queryClient, id, subject, to]
	);

	//
	useEffect(() => {
		if (!ready) return;
		if (isLoading) return;
		if (data?.email) {
			setSubject(data.email.subject);
			setName(data.email.name);
			if (data.email.unlayerDesign) {
				setDesignString(data.email.unlayerDesign);
				emailEditorRef.current.editor.loadDesign(JSON.parse(data.email.unlayerDesign));
			} else emailEditorRef.current.editor.loadBlank();
		}
	}, [data, isLoading, ready, id]);

	const createNewTemplate = async () => {
		swal
			.fire({
				title: 'Create Template',
				text: 'Please type a name for your template',
				input: 'text',
				showCancelButton: true
			})
			.then(async (result) => {
				if (result.isConfirmed) {
					setSaving(true);
					const { design, html, text } = await exportContent();
					const id = await saveTemplate({
						unlayerDesign: JSON.stringify(design),
						unlayerHtml: html,
						unlayerText: text,
						subject,
						name
					});
					window.location = `/admin/unlayer/${id}`;
				}
			});
	};

	const save = async (id) => {
		setSaving(true);
		const { design, html, text } = await exportContent();
		await saveTemplate({
			unlayerDesign: JSON.stringify(design),
			unlayerHtml: html,
			unlayerText: text,
			name,
			subject,
			id
		});
		setSaving(false);
	};

	const exportContent = () => {
		return new Promise((resolve, reject) => {
			emailEditorRef.current.editor.exportHtml(async ({ design, html }) => {
				emailEditorRef.current.editor.exportPlainText(({ text }) => {
					resolve({ design, html, text });
				});
			});
		});
	};

	// auto save if exportContents design changes
	useEffect(() => {
		if (!ready) return;
		if (isLoading) return;
		if (data?.email) {
			const interval = setInterval(async () => {
				const { design, html, text } = await exportContent();
				if (JSON.stringify(design) !== designString) {
					setDesignString(JSON.stringify(design));
					saveTemplate(
						{ unlayerDesign: JSON.stringify(design), unlayerHtml: html, unlayerText: text, name, subject, id },
						false
					);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [data, isLoading, ready, id, designString]);

	if (isLoading) return <Spinner />;
	return (
		<>
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<Button style={{ height: '58px' }} outline onClick={() => navigate('/admin/emails')} color="primary">
						<i className="fas fa-arrow-left" />
						&nbsp; Back
					</Button>

					<UncontrolledDropdown style={{ height: '58px' }} group>
						<Button color="primary" onClick={() => save(id)}>
							{/* fa spinner */}
							{saving ? <i className="fas fa-refresh fa-spin" /> : <>Save</>}
						</Button>
						<DropdownToggle caret color="primary" />
						<DropdownMenu>
							<DropdownItem onClick={() => createNewTemplate()}>Save as new template</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

					<FormGroup style={{ width: '200px' }} floating>
						<Input value={name} type="text" onChange={(e) => setName(e.target.value)} />
						<Label for="email">Name</Label>
					</FormGroup>

					<FormGroup style={{ width: '500px' }} floating>
						<Input value={subject} type="text" onChange={(e) => setSubject(e.target.value)} />
						<Label for="email">Subject</Label>
					</FormGroup>

					<PickerModal roles={[]} list={to} setList={setTo} listType="to" />

					<SendModal emailId={id} text={text} subject={subject} html={html} to={to} generateHTML={generateHTML}>
						<Button style={{ height: '58px' }} color="primary">
							Send
						</Button>
					</SendModal>
				</div>

				<EmailEditor
					style={{
						height: '100vh'
					}}
					onReady={() => setReady(true)}
					ref={emailEditorRef}
				/>
			</div>
		</>
	);
}
