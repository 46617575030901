import React from 'react';
import { Container } from 'reactstrap';
import './index.css';

export default function Footer() {
	if (window?.websiteEmbed) return <></>;
	return (
		<Container style={{ marginTop: '50px' }}>
			<div className="row footer-component">
				<div className="col-md-3">
					<img style={{ width: '70%' }} src="/gd-logo.png" />
				</div>
				<div className="col-md-7">
					<h4 className="footerTitle">Contact Us</h4>
					<a className="link" href="https://goo.gl/maps/uvLWwHrfeFVj6vDH6">
						<i className="linkIcon fa fa-map-pin"></i> Warren Barn Farm, Slines Oak Rd, Woldingham, CR3 7HN
					</a>
					<a className="link" href="tel:+441883652666">
						<i className="linkIcon fa fa-phone"></i> 01883 652 666
					</a>
					<a className="link" href="tel:+44860875567">
						<i className="linkIcon fas fa-mobile-alt"></i> 07860 875567
					</a>
					<a className="link" href="mail:admin@greendragons.co.uk">
						<i className="linkIcon far fa-envelope"></i> admin@greendragons.co.uk
					</a>
				</div>
				<div className="col-md-2">
					<img style={{ width: '40%' }} src="/BHPA-Logo-Black-202x300.png" />
				</div>
			</div>
		</Container>
	);
}
