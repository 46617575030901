import React, { useContext } from 'react';
// import useCart from 'src/utils/CartContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { CartContext } from 'src/contexts/CartContext';

export default function CartThankYou() {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const cart = useContext(CartContext);

	React.useEffect(() => {
		if (searchParams.get('noempty')) return;
		cart.clearCart();
		cart.clearCodes();
	}, []);

	return (
		<Container>
			<Row style={{ justifyContent: 'space-around' }}>
				<Col md={7}>
					<Card style={{ marginTop: '5vh' }}>
						<CardBody style={{ textAlign: 'center' }}>
							<h3>Thank you for your purchase</h3>
							<p>
								<div className="mb-3">
									We're just processing your order and will send you further instructions by email in the next few
									minutes.
								</div>
								<i style={{ fontSize: '4em' }} className="far fa-envelope my-4"></i>
								<div>Your instructions will be sent to:</div>
								<div style={{ fontWeight: 'bold' }}>{searchParams.get('email')}</div>

								<br />
								<Button
									outline
									color="primary"
									onClick={() => {
										navigate('/');
									}}
								>
									Continue
								</Button>
							</p>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
